import React, { useCallback } from 'react';

import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';

import UniteGestionFormDialog from '@/features/shared/unite-gestion/UniteGestionFormDialog';

import CRUDDataGrid from '@/components/CRUDDataGrid';
import ManyItemsCell from '@/components/ManyItemsCell';
import SylvalibreUgLink from '@/features/fiche-contrat/sylvalibre-link/SylvalibreUgLink';
import SylvalibreLinkCell from '@/features/shared/unite-gestion/SylvalibreLinkCell';

export default function UniteGestionTable({ unitesGestionList, datagridSx }) {
  const stationsList = useSelector(
    (store) => store.itemReducer.filteredItems.stations
  );

  const columns = [
    {
      field: 'nom',
      headerName: 'Nom',
      cellClassName: 'datagrid-cell',
      width: 100,
      editable: false,
    },
    {
      field: 'surface_sig_ug',
      headerName: 'Surface unité de gestion',
      width: 180,
      editable: false,
    },
    {
      field: 'itineraire_sylvicole_preconise',
      headerName: 'Itinéraire sylvicole préconisé',
      valueGetter: (value) => value?.nom,
      width: 250,
      editable: false,
    },
    {
      field: 'peuplement_initial',
      headerName: 'Peuplement existant',
      valueGetter: (value) => value?.type.nom,
      width: 210,
      editable: false,
    },
    {
      field: 'essences_principales_existantes',
      headerName: 'Essences principales existantes',
      width: 220,
      editable: false,
      valueGetter: (value) =>
        value?.essences_list
          .filter((value) => value.type === 'Principale')
          .map((value) => value?.essence.nom_vernaculaire),
      renderCell: ({ row }) => (
        <ManyItemsCell
          itemId={row.id}
          relatedItems={{
            relatedItemsList:
              row.peuplement_initial?.essences_list.filter(
                (value) => value.type === 'Principale'
              ) || [],
            getRelatedName: (value) => value?.essence.nom_vernaculaire,
            relatedVerboseName: 'essence',
          }}
        />
      ),
    },
    {
      field: 'peuplement_objectif',
      headerName: 'Peuplement objectif',
      valueGetter: (value) => value?.nom,
      width: 150,
      editable: false,
    },
    {
      field: 'essences_principales_objectives',
      headerName: 'Essences principales objectives',
      width: 220,
      editable: false,
      valueGetter: (_, row) =>
        row.essences_list
          .filter((essence) => essence.type === 'Principale')
          .map((essence) => essence?.nom_vernaculaire),
      renderCell: ({ row }) => (
        <ManyItemsCell
          itemId={row.id}
          relatedItems={{
            relatedItemsList: row.essences_list?.filter(
              (essence) => essence.type === 'Principale'
            ),
            getRelatedName: (essence_parcelle) =>
              essence_parcelle?.essence.nom_vernaculaire,
            relatedVerboseName: 'essence',
          }}
        />
      ),
    },
    {
      field: 'station',
      headerName: 'Station',
      valueGetter: (value) => value?.numero,
      width: 100,
      editable: false,
    },
    {
      field: 'sylvalibre_ug_uuid',
      headerName: 'Lien sylvalibre',
      valueGetter: (value) => (value ? 'Lien établi' : 'Lien à établir'),
      renderCell: ({ row }) => <SylvalibreLinkCell row={row} />,
      valueOptions: ['Lien établi', 'Lien à établir'],
      width: 150,
      editable: false,
    },
  ];

  const onDuplicate = useCallback((rowToDuplicate) => {
    const newRow = {
      ...rowToDuplicate,
      nom: `${rowToDuplicate.nom} (copie)`,
      id: null,
      rowId: null,
      sylvalibre_ug_uuid: null,
      sylvalibre_latest_sync_datetime: null,
    };

    const cloneObject = (object) => {
      return { ...object, id: null };
    };

    newRow.essences_list = newRow.essences_list?.map(cloneObject);
    newRow.interventions_list = newRow.interventions_list?.map(cloneObject);
    newRow.peuplement_initial = cloneObject(newRow.peuplement_initial);
    newRow.peuplement_initial.essences_list =
      newRow.peuplement_initial.essences_list?.map(cloneObject);

    return newRow;
  }, []);

  const proprieteLink = useSelector(
    (store) => store.sylvalibreReducer.proprieteLink
  );

  return (
    <Box>
      <CRUDDataGrid
        itemsList={unitesGestionList}
        columns={columns}
        itemType='unites_gestion'
        addItemLabel='Ajouter une unité de gestion'
        modal={{
          component: UniteGestionFormDialog,
          props: {
            stationsList,
            SylvalibreLinkComponent: SylvalibreUgLink,
            sylvalibreUgLinkIsAvailable: Boolean(proprieteLink),
          },
        }}
        onDuplicate={onDuplicate}
        canDelete={(item) => !item.sylvalibre_ug_uuid}
        sx={datagridSx}
        alternateRowColor
        headerClassName='datagrid-header'
        onSubmitMessage={'Pensez à sauvegarder le contrat.'}
      />
    </Box>
  );
}

UniteGestionTable.propTypes = {
  unitesGestionList: PropTypes.array,
  datagridSx: PropTypes.object.isRequired,
};
