import React from 'react';

import { useParams } from 'react-router-dom';

import useItem from '@/hooks/useItem';

import CouvertExistant from '@/features/sylvalibre-mapping/couvert-existant/CouvertExistantForm';

import Container from '@mui/material/Container';

export default function SylvalibreCouvertExistant() {
  const params = useParams();

  const itemId = params.id ? parseInt(params.id, 10) : undefined;

  const emptyItem = {
    legende: null,
    peuplement_initial_type: null,
  };

  const item = useItem(itemId, 'sylvalibre_couvert_existant', emptyItem);

  return (
    <Container component='main' maxWidth={false} sx={{ my: 2 }}>
      {item && <CouvertExistant item={item} />}
    </Container>
  );
}
