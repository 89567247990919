import { useMemo } from 'react';

import { useTheme } from '@mui/material/styles';

export default function useDatagridSx(extraSx = {}) {
  const theme = useTheme();

  return useMemo(
    () => ({
      '.datagrid-header': {
        backgroundColor: theme.palette.primary.main,
        color: 'white',
      },
      '.datagrid-cell': {
        backgroundColor: theme.palette.primary.main,
        color: 'white',
      },
      '.font-bold': {
        fontWeight: 'bold',
      },
      ...extraSx,
    }),
    [theme]
  );
}
