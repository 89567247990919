import React from 'react';

import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import Button from '@mui/material/Button';
import SyncIcon from '@mui/icons-material/Sync';

import useConfirmationDialog from '@/hooks/useConfirmationDialog';
import { syncUg } from '@/slices/sylvalibreSlice';

export default function SyncUg({ uniteGestion }) {
  const dispatch = useDispatch();

  const { open } = useConfirmationDialog();

  const onSyncUgClick = async () => {
    const result = await open({
      content:
        'Attention, vous allez synchroniser cette unité de gestion avec Sylvalibre, ce qui peut engendrer des pertes de données. Souhaitez-vous continuer ?',
    });

    if (result === 'confirm') {
      dispatch(
        syncUg({
          contratId: uniteGestion?.contrat_proprietaire?.id,
          uniteGestionId: uniteGestion?.id,
          proprieteId: uniteGestion?.propriete?.id,
        })
      );
    }
  };

  return (
    <Button
      onClick={onSyncUgClick}
      variant='contained'
      startIcon={<SyncIcon />}
    >
      {"Synchroniser l'UG"}
    </Button>
  );
}

SyncUg.propTypes = {
  uniteGestion: PropTypes.object,
};
