import React from 'react';

import store from './store';

import { fr } from 'date-fns/locale/fr';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { frFR } from '@mui/x-date-pickers/locales';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import App from './App';
import './index.css';
import theme from './theme';

const container = document.getElementById('root');
const root = createRoot(container);

const frenchLocal =
  frFR.components.MuiLocalizationProvider.defaultProps.localeText;

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <LocalizationProvider
        dateAdapter={AdapterDateFns}
        adapterLocale={fr}
        localeText={frenchLocal}
      >
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <BrowserRouter
            future={{
              v7_relativeSplatPath: true,
              v7_startTransition: true,
              v7_fetcherPersist: true,
              v7_normalizeFormMethod: true,
              v7_partialHydration: true,
            }}
          >
            <App />
          </BrowserRouter>
        </ThemeProvider>
      </LocalizationProvider>
    </Provider>
  </React.StrictMode>
);
