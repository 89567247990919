import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import { useForm, useWatch } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import CloseIcon from '@mui/icons-material/Close';
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import LinearProgress from '@mui/material/LinearProgress';

import useChoices from '@/hooks/useChoices';
import useOnUnmount from '@/hooks/useOnUnmount';

import SelectField from '@/components/controlled-fields/SelectField';
import AreaTextField from '@/components/controlled-fields/text-fields/AreaTextField';
import BaseTextField from '@/components/controlled-fields/text-fields/BaseTextField';
import MultilineTextField from '@/components/controlled-fields/text-fields/MultilineTextField';
import NumberTextField from '@/components/controlled-fields/text-fields/NumberTextField';
import YearTextField from '@/components/controlled-fields/text-fields/YearTextField';
import DividerTitle from '@/components/DividerTitle';
import CRUDDataGrid from '@/components/CRUDDataGrid';

import EssencePrincipaleFormDialog from './essence/EssencePrincipaleFormDialog';
import EssenceSecondaireFormDialog from './essence/EssenceSecondaireFormDialog';
import InterventionFormDialog from './intervention/InterventionFormDialog';

import { clearUgLink } from '@/slices/sylvalibreSlice';
import { setFilteredItems } from '@/slices/itemSlice';

const useEssence = (essenceList, valueName) => {
  const [essences, setEssences] = useState({
    principales: [],
    secondaires: [],
  });
  const [total, setTotal] = useState(0);

  useEffect(() => {
    setEssences({
      principales: essenceList?.filter(
        (essence) => essence.type === 'Principale'
      ),
      secondaires: essenceList?.filter(
        (essence) => essence.type === 'Secondaire'
      ),
    });

    const total = essenceList
      ?.filter((p) => p[valueName])
      .reduce((acc, val) => acc + parseFloat(val[valueName]), 0);

    const roundedTotalSurfaceTerriere = Math.round(total * 100) / 100;

    setTotal(roundedTotalSurfaceTerriere);
  }, [essenceList]);

  return {
    total,
    essences,
  };
};

export default function UniteGestionForm({
  editItem,
  getRefreshItem,
  stationsList,
  onSubmit,
  handleClose,
  SylvalibreLinkComponent,
  sylvalibreUgLinkIsAvailable = false,
}) {
  const dispatch = useDispatch();

  const isLoading = useSelector((store) => store.isLoadingReducer.isLoading);

  const [item, setItem] = useState(editItem);

  const uniteGestionchoices = useChoices('unites_gestion');
  const peuplementInitialChoices = useChoices('peuplements_initiaux');

  // Sylvalibre
  const withSylvalibreLinkComponent = Boolean(SylvalibreLinkComponent);

  const sylvalibreLinkState = useSelector(
    (state) => state.sylvalibreReducer.ugLink
  );

  useEffect(() => {
    if (sylvalibreLinkState?.latest_sync_datetime) {
      const refreshedItem = getRefreshItem(item?.id);
      setItem(refreshedItem);
    }
  }, [sylvalibreLinkState?.latest_sync_datetime, getRefreshItem, item?.id]);

  const isSylvalibreSynced = Boolean(
    withSylvalibreLinkComponent
      ? sylvalibreLinkState?.latest_sync_datetime
      : item?.sylvalibre_latest_sync_datetime
  );

  useOnUnmount(() => dispatch(clearUgLink()));

  const onUniteGestionSubmit = (uniteGestion) => {
    uniteGestion.interventions_list = interventionsList;
    uniteGestion.essences_list = essencesParcellesList;
    if (!withSylvalibreLinkComponent) {
      uniteGestion.temp_uuid = uniteGestion.station?.temp_uuid;
    }
    uniteGestion.peuplement_initial.essences_list =
      peuplementInitialEssencesList;

    if (uniteGestion?.sylvalibre_ug_uuid !== sylvalibreLinkState?.uuid) {
      uniteGestion.sylvalibre_ug_uuid = sylvalibreLinkState?.uuid;
    }

    return onSubmit(uniteGestion);
  };

  const interventionsList = useSelector(
    (store) => store.itemReducer.filteredItems.interventions
  );
  const essencesParcellesList = useSelector(
    (store) => store.itemReducer.filteredItems.peuplements_objectifs_essences
  );
  const peuplementInitialEssencesList = useSelector(
    (store) => store.itemReducer.filteredItems.essences_peuplement_initial
  );

  useEffect(() => {
    dispatch(
      setFilteredItems({
        itemsList: item?.interventions_list ? item.interventions_list : [],
        itemType: 'interventions',
      })
    );
    dispatch(
      setFilteredItems({
        itemsList: item?.essences_list || [],
        itemType: 'peuplements_objectifs_essences',
      })
    );
    dispatch(
      setFilteredItems({
        itemsList: item?.peuplement_initial?.essences_list || [],
        itemType: 'essences_peuplement_initial',
      })
    );
  }, [item]);

  const surfaceTerriereProps = {
    name: 'pourcentage_surface_terriere',
    verboseName: '% de surface terrière',
  };

  const proportionProps = {
    name: 'proportion',
    verboseName: 'Proportion (%)',
  };

  const essenceParcelle = useEssence(
    essencesParcellesList,
    proportionProps.name
  );
  const peuplementInitial = useEssence(
    peuplementInitialEssencesList,
    surfaceTerriereProps.name
  );

  const getEssencesColumns = (valueProps) => {
    return [
      {
        field: 'essence',
        headerName: 'Essence',
        width: 220,
        editable: false,
        valueGetter: (value) => value.nom_vernaculaire,
      },
      {
        field: valueProps.name,
        headerName: valueProps.verboseName,
        width: 160,
        editable: false,
        valueFormatter: (value) => {
          if (typeof value === 'string') {
            value = Number(value);
          }
          return value ? value.toFixed(2) : null;
        },
      },
    ];
  };

  const interventionsColumns = [
    {
      field: 'type',
      headerName: 'Type',
      width: 220,
      editable: false,
      valueGetter: (value) => value.nom,
    },
    {
      field: 'annee_prevue',
      headerName: 'Année prévue',
      width: 220,
      editable: false,
    },
    {
      field: 'commentaire',
      headerName: 'Commentaire',
      width: 220,
      editable: false,
    },
  ];

  const {
    handleSubmit,
    control,
    formState: { isValid },
    setValue,
    trigger,
    reset,
  } = useForm({
    defaultValues: item || {
      nom: null,
      surface_sig_ug: null,
      peuplement_objectif: null,
      peuplement_initial: null,
      itineraire_sylvicole_preconise: null,
      interventions_list: [],
      essences_list: [],
      densite: null,
    },
    reValidateMode: 'onBlur',
    mode: 'onChange',
  });

  useEffect(() => {
    reset(item);
  }, [item, reset]);

  const onClearStation = () => {
    setValue('station', null, { shouldValidate: true });
  };
  const onClearTypeTraitement = () => {
    setValue('type_traitement', null, { shouldValidate: true });
  };

  const peuplementInitialType = useWatch({
    control,
    name: 'peuplement_initial.type',
  });

  const [
    withPeuplementInitialOptionalFields,
    setWithPeuplementInitialOptionalFields,
  ] = useState(false);

  useEffect(() => {
    setWithPeuplementInitialOptionalFields(
      ![
        'ANC_CULTURE',
        'ANC_VIGNE',
        'ANC_VERGER',
        'PP_RECENTE',
        'PP_ANCIENNE',
        'FRICHE',
        'HORS_SYLVICULTURE',
      ].includes(peuplementInitialType?.code)
    );
  }, [peuplementInitialType]);

  const itineraireSylvicole = useWatch({
    control,
    name: 'itineraire_sylvicole_preconise',
  });

  const [withItineraireOptionalFields, setWithItineraireOptionalFields] =
    useState(false);

  useEffect(() => {
    const diplayOptionalFields = [
      'RENOUVELLEMENT',
      'BOISEMENT',
      'AMELIORATION',
    ].includes(itineraireSylvicole?.code);
    setWithItineraireOptionalFields(diplayOptionalFields);
  }, [itineraireSylvicole]);

  // Trigger validation on optional fields change
  useEffect(() => {
    trigger(['peuplement_objectif', 'type_traitement', 'densite']);
  }, [withItineraireOptionalFields]);

  useEffect(() => {
    trigger([
      'peuplement_initial.surface_terriere',
      'peuplement_initial.annee',
      'peuplement_initial.structure',
    ]);
  }, [withPeuplementInitialOptionalFields]);

  const [valeurEcoByHa, setValeurEcoByHa] = useState(null);

  const [surfaceParcelle, setSurfaceParcelle] = useState(item?.surface_sig_ug);
  const [valeurEco, setValeurEco] = useState(
    item?.peuplement_initial?.valeur_eco_estimee
  );

  useEffect(() => {
    if (valeurEco && surfaceParcelle) {
      // Convert surfaceParcelle to a float, replacing comma with dot if necessary
      const surfaceParcelleFloat = parseFloat(
        surfaceParcelle.toString().replace(',', '.')
      );
      // Convert valeurEco to a float, replacing comma with dot if necessary
      const valeurEcoFloat = parseFloat(valeurEco.toString().replace(',', '.'));

      if (!isNaN(surfaceParcelleFloat) && surfaceParcelleFloat !== 0) {
        setValeurEcoByHa((valeurEcoFloat / surfaceParcelleFloat).toFixed(2));
      } else {
        setValeurEcoByHa(null); // Avoid displaying NaN
      }
    } else {
      if (valeurEcoByHa) {
        setValeurEco(null);
      }
    }
  }, [surfaceParcelle, valeurEco]);

  return (
    <>
      <AppBar sx={{ position: 'relative' }}>
        <Toolbar>
          <IconButton
            edge='start'
            color='inherit'
            onClick={handleClose}
            aria-label='close'
          >
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant='h6' component='div'>
            Néosylva
          </Typography>
          <Button
            autoFocus
            color='inherit'
            disabled={!isValid}
            onClick={handleSubmit(onUniteGestionSubmit)}
          >
            {item ? 'Éditer' : 'Créer'}
          </Button>
        </Toolbar>
        {isLoading && <LinearProgress color='secondary' />}
      </AppBar>
      <DialogTitle>
        {item ? 'Éditer' : 'Créer'} une unité de gestion
      </DialogTitle>
      <DialogContent>
        <form>
          {withSylvalibreLinkComponent ? (
            <SylvalibreLinkComponent
              uniteGestion={item}
              ugLinkIsAvailable={sylvalibreUgLinkIsAvailable}
            />
          ) : (
            isSylvalibreSynced && (
              <Typography variant='subtitle2' component='div' color='secondary' sx={{ mb: 1 }}>
                Cette UG est synchronisée à Sylvalibre, certains champs ne sont
                par conséquent par modifiables.
              </Typography>
            )
          )}
          <Grid container spacing={2} sx={{ mt: 0 }} alignItems='center'>
            <Grid item xs={12} sm={4} md={3}>
              <BaseTextField
                control={control}
                name='nom'
                maxLength={256}
                required='Nom requis.'
                label='Nom'
                disabled={isSylvalibreSynced}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <AreaTextField
                control={control}
                name='surface_sig_ug'
                required='Surface unité de gestion requise.'
                label='Surface unité de gestion (ha)'
                disabled={isSylvalibreSynced}
                customOnChange={setSurfaceParcelle}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <SelectField
                control={control}
                name='station'
                label='Station correspondante'
                values={stationsList}
                renderValue={(station) => station?.numero}
                itemMapper={(station) => ({
                  key: withSylvalibreLinkComponent
                    ? station?.id
                    : station?.temp_uuid,
                  label: station?.numero,
                })}
                onClear={onClearStation}
                disabled={stationsList?.length === 0}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={{ mt: 1 }} alignItems='center'>
            <Grid item xs={12}>
              <DividerTitle text={'Peuplement existant'} />
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={{ mt: 1 }} alignItems='center'>
            <Grid item xs={12} sm={6} md={4}>
              <SelectField
                control={control}
                name='peuplement_initial.type'
                required='Type de peuplement requis.'
                baseId='type-pplt'
                label='Type de peuplement'
                values={peuplementInitialChoices?.peuplements_initiaux_types}
                renderValue={(value) => value.nom}
                itemMapper={(type_peuplement) => ({
                  key: type_peuplement.code,
                  label: type_peuplement.nom,
                })}
                disabled={isSylvalibreSynced}
              />
            </Grid>
            {withPeuplementInitialOptionalFields && (
              <Grid item xs={12} sm={6} md={3}>
                <NumberTextField
                  control={control}
                  name='peuplement_initial.surface_terriere'
                  label='Surface terrière (G en m²/ha)'
                  pattern={{
                    value: /^\d+([.,]\d?)?$/,
                    message: 'Surface terrière invalide.',
                  }}
                  decimal={1}
                />
              </Grid>
            )}
          </Grid>
          <Grid container spacing={2} sx={{ mt: 1 }} alignItems='center'>
            {withPeuplementInitialOptionalFields && (
              <>
                <Grid item xs={12} sm={8} md={5}>
                  <CRUDDataGrid
                    itemsList={peuplementInitial.essences.principales}
                    relatedItemsList={peuplementInitial.essences.secondaires}
                    columns={getEssencesColumns(surfaceTerriereProps)}
                    itemType={'essences_peuplement_initial'}
                    modal={{
                      component: EssencePrincipaleFormDialog,
                      props: {
                        totalValue: peuplementInitial.total,
                        valueProps: surfaceTerriereProps,
                      },
                    }}
                    title={'Essences principales'}
                    addItemLabel={'Ajouter une essence principale'}
                  />
                </Grid>
                <Grid item xs={12} sm={8} md={5}>
                  <CRUDDataGrid
                    itemsList={peuplementInitial.essences.secondaires}
                    relatedItemsList={peuplementInitial.essences.principales}
                    columns={getEssencesColumns(surfaceTerriereProps)}
                    itemType={'essences_peuplement_initial'}
                    modal={{
                      component: EssenceSecondaireFormDialog,
                      props: {
                        totalValue: peuplementInitial.total,
                        valueProps: surfaceTerriereProps,
                      },
                    }}
                    title={'Essences secondaires'}
                    addItemLabel={'Ajouter une essence secondaire'}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <YearTextField
                    control={control}
                    name='peuplement_initial.annee'
                    label="Année d'installation du peuplement"
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <SelectField
                    control={control}
                    name='peuplement_initial.structure'
                    baseId='structure'
                    label='Structure'
                    renderValue={(value) => value.nom}
                    values={peuplementInitialChoices?.structures}
                    itemMapper={(structure) => ({
                      key: structure.code,
                      label: structure.nom,
                    })}
                  />
                </Grid>
              </>
            )}
            <Grid item xs={12} sm={12} md={4}>
              <MultilineTextField
                control={control}
                name='peuplement_initial.qualite_etat_sanitaire'
                label='Qualité/état sanitaire'
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <NumberTextField
                control={control}
                name='peuplement_initial.valeur_eco_estimee'
                pattern={{
                  value: /^\d+([.,]\d{0,2})?$/,
                  message: 'Valeur économique totale estimée invalide.',
                }}
                customOnChange={setValeurEco}
                label='Valeur économique totale estimée (€)'
                decimal={2}
              />
              {valeurEcoByHa && (
                <Typography
                  mt={1}
                  variant='subtitle2'
                  component='div'
                  sx={{ color: 'primary.light' }}
                >
                  Soit environ {valeurEcoByHa} €/ha
                </Typography>
              )}
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <MultilineTextField
                control={control}
                name='peuplement_initial.observations'
                label='Observations'
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={{ mt: 1 }} alignItems='center'>
            <Grid item xs={12}>
              <DividerTitle text={'Itinéraires sylvicoles'} />
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={{ mt: 1 }} alignItems='center'>
            <Grid item xs={12} sm={6} md={3}>
              <SelectField
                control={control}
                name='itineraire_sylvicole_preconise'
                required='Itinéraire sylvicole préconisé requis.'
                baseId='itineraire'
                label='Itinéraire sylvicole préconisé'
                renderValue={(value) => value.nom}
                values={uniteGestionchoices?.itineraires_sylvicoles_preconises}
                itemMapper={(itineraire) => ({
                  key: itineraire.id,
                  label: itineraire.nom,
                })}
                disabled={isSylvalibreSynced}
              />
            </Grid>
            {withItineraireOptionalFields && (
              <>
                <Grid item xs={12} sm={6} md={3}>
                  <SelectField
                    control={control}
                    name='peuplement_objectif'
                    required='Peuplement objectif requis.'
                    baseId='type-pplt-objectif'
                    label='Peuplement objectif'
                    renderValue={(value) => value.nom}
                    values={uniteGestionchoices?.peuplements_objectifs_types}
                    itemMapper={(type_peuplement) => ({
                      key: type_peuplement.code,
                      label: type_peuplement.nom,
                    })}
                    disabled={isSylvalibreSynced}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <SelectField
                    control={control}
                    name='type_traitement'
                    label='Type de traitement'
                    renderValue={(value) => value.nom}
                    values={uniteGestionchoices?.types_traitements}
                    itemMapper={(type_traitement) => ({
                      key: type_traitement.id,
                      label: type_traitement.nom,
                    })}
                    onClear={onClearTypeTraitement}
                    disabled={isSylvalibreSynced}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <NumberTextField
                    control={control}
                    name='densite'
                    pattern={{
                      value: /^\d+$/,
                      message: 'Densité invalide.',
                    }}
                    label='Densité'
                    decimal={0}
                  />
                </Grid>
              </>
            )}
          </Grid>
          {withItineraireOptionalFields && (
            <Grid container spacing={2} sx={{ mt: 1 }} alignItems='center'>
              <Grid item xs={12} sm={8} md={5}>
                <CRUDDataGrid
                  itemsList={essenceParcelle.essences.principales}
                  relatedItemsList={essenceParcelle.essences.secondaires}
                  columns={getEssencesColumns(proportionProps)}
                  itemType={'peuplements_objectifs_essences'}
                  modal={{
                    component: EssencePrincipaleFormDialog,
                    props: {
                      totalValue: essenceParcelle.total,
                      valueProps: proportionProps,
                    },
                  }}
                  title={'Essences principales'}
                  addItemLabel={'Ajouter une essence principale'}
                />
              </Grid>
              <Grid item xs={12} sm={8} md={5}>
                <CRUDDataGrid
                  itemsList={essenceParcelle.essences.secondaires}
                  relatedItemsList={essenceParcelle.essences.principales}
                  columns={getEssencesColumns(proportionProps)}
                  itemType={'peuplements_objectifs_essences'}
                  modal={{
                    component: EssenceSecondaireFormDialog,
                    props: {
                      totalValue: essenceParcelle.total,
                      valueProps: proportionProps,
                    },
                  }}
                  title={'Essences secondaires'}
                  addItemLabel={'Ajouter une essence secondaire'}
                />
              </Grid>
            </Grid>
          )}
          <Grid container spacing={2} sx={{ mt: 1 }} alignItems='center'>
            <Grid item xs={12} md={8}>
              <CRUDDataGrid
                itemsList={interventionsList}
                columns={interventionsColumns}
                itemType={'interventions'}
                modal={{ component: InterventionFormDialog }}
                title={'Interventions'}
                addItemLabel={'Ajouter une intervention'}
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </>
  );
}

UniteGestionForm.propTypes = {
  editItem: PropTypes.object,
  getRefreshItem: PropTypes.func.isRequired,
  stationsList: PropTypes.array,
  onSubmit: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  SylvalibreLinkComponent: PropTypes.elementType,
  sylvalibreUgLinkIsAvailable: PropTypes.bool,
};
