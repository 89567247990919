import React from 'react';

import { useParams } from 'react-router-dom';

import useItem from '@/hooks/useItem';

import OrientationsGestionForm from '@/features/sylvalibre-mapping/orientations-gestion/OrientationsGestionForm';

import Container from '@mui/material/Container';

export default function SylvalibreOrientationsGestion() {
  const params = useParams();

  const itemId = params.id ? parseInt(params.id, 10) : undefined;

  const emptyItem = {
    legende: null,
    peuplement_objectif_type: null,
    type_traitement: null
  };

  const item = useItem(itemId, 'sylvalibre_orientations_gestion', emptyItem);

  return (
    <Container component='main' maxWidth={false} sx={{ my: 2 }}>
      {item && <OrientationsGestionForm item={item} />}
    </Container>
  );
}
