import React from 'react';

import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';

import useConfirmationDialog from '@/hooks/useConfirmationDialog';

import { removeUgLink } from '@/slices/sylvalibreSlice';

export default function RemoveLink({ uniteGestion }) {
  const dispatch = useDispatch();

  const { open } = useConfirmationDialog();

  const onRemoveLinkClick = async () => {
    const result = await open({
      content: "Confirmer la suppression du lien vers l'UG Sylvalibre ?",
    });

    if (result === 'confirm') {
      dispatch(
        removeUgLink({
          proprieteId: uniteGestion?.propriete?.id,
          uniteGestionId: uniteGestion?.id,
        })
      );
    }
  };

  return (
    <Button
      onClick={onRemoveLinkClick}
      variant='outlined'
      startIcon={<CloseIcon />}
    >
      Rompre la connexion
    </Button>
  );
}

RemoveLink.propTypes = {
  uniteGestion: PropTypes.object,
};
