import React, { useRef } from 'react';

import PropTypes from 'prop-types';
import { Controller, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';

import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Select from '@mui/material/Select';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import useChoices from '@/hooks/useChoices';
import useConfirmationDialog from '@/hooks/useConfirmationDialog';
import useFetchFilteredItems from '@/hooks/useFetchFilteredItems';
import useFormItem from '@/hooks/useFormItem';
import useItems from '@/hooks/useItems';
import useMultipleConfirmationDialog from '@/hooks/useMultipleConfirmationDialog';
import useOnMount from '@/hooks/useOnMount';

import AdresseField from '@/features/shared/AdresseField';
import EntrepriseField from '@/features/shared/EntrepriseField';

import FormSubmitButtons from '@/features/shared/FormSubmitButtons';
import useConditionalRequired from '@/hooks/useConditionalRequired';

import MailTextField from '@/components/controlled-fields/text-fields/MailTextField';
import DatePickerField from '@/components/controlled-fields/DatePickerField';
import SwitchField from '@/components/controlled-fields/SwitchField';
import DividerTitle from '@/components/DividerTitle';
import BaseTextField from '@/components/controlled-fields/text-fields/BaseTextField';

export default function ContactForm({ item }) {
  const { open } = useConfirmationDialog();

  const usersList = useItems('users');

  const isFetching = useSelector(
    (store) => store.itemReducer.pendingActions !== 0
  );

  const gestionnaireContratsRef = useRef(null);
  const referentTechniqueContratRef = useRef(null);
  const fetchFilteredItems = useFetchFilteredItems();

  /**
   * Récupère les contrats, en lien avec le rôle de Propriétaire (référent technique) et de Gestionnaire
   */
  useOnMount(() => {
    const activitesListCode = item.activites_list?.map(
      (activite) => activite.code
    );
    const isGestionnaire = activitesListCode.includes('GF');
    const isProprietaire = activitesListCode.includes('PROP');

    isProprietaire &&
      fetchFilteredItems({
        filter: { item_type: 'proprietaire', item_id: item.id },
        itemTypes: ['contrats_proprietaires'],
      }).then(
        ([
          {
            payload: { itemsList },
          },
        ]) => (referentTechniqueContratRef.current = itemsList)
      );

    isGestionnaire &&
      fetchFilteredItems({
        filter: { item_type: 'gestionnaire', item_id: item.id },
        itemTypes: ['contrats_proprietaires'],
      }).then(
        ([
          {
            payload: { itemsList },
          },
        ]) => (gestionnaireContratsRef.current = itemsList)
      );
  });

  const itemType = 'contacts';

  const choices = useChoices(itemType);

  const entreprises = useItems('entreprises');

  const { onCancel, onSubmit } = useFormItem(item.id, itemType);

  const {
    getValues,
    handleSubmit,
    control,
    setValue,
    trigger,
    formState: { isDirty, isValid },
  } = useForm({
    defaultValues: item,
    reValidateMode: 'onBlur',
    mode: 'onChange',
  });

  const conditionalRequiredFields = [
    ['telephone_fixe'],
    ['telephone_portable'],
    ['email'],
  ];

  const requiredMapper = useConditionalRequired(
    conditionalRequiredFields,
    control,
    trigger
  );

  const criticalActivitesList = item.activites_list.filter((activite) =>
    activite.code.match('PROP|GF|ETF|FINCARB')
  );

  const handleDeleteCriticalActivitesDialog = async () => {
    const updatedActivites = getValues('activites_list');

    const removedCriticalActivites = criticalActivitesList.filter(
      (activite) =>
        !updatedActivites.map((act) => act.code).includes(activite.code)
    );

    const activitesNames = removedCriticalActivites
      .map((activite) => `"${activite.nom}"`)
      .join(', ');

    return (
      removedCriticalActivites.length === 0 ||
      (await open({
        title: 'Suppression activité(s)',
        content: `Confirmer la suppression de : ${activitesNames} ? Cela peut entraîner la suppression de données liées.`,
      })) === 'confirm'
    );
  };

  const handleDeadInactiveAccountDialog = async () => {
    if (getValues('decede_inactif') && !item.decede_inactif) {
      let content = '';
      if (referentTechniqueContratRef.current?.length) {
        content += 'Liste des contrats rattachés au propriétaire :\n';
        for (const contrat of referentTechniqueContratRef.current) {
          content += `• ${contrat.code_chantier}\n`;
        }
      }

      if (gestionnaireContratsRef.current?.length) {
        content += 'Liste des contrats rattachés au gestionnaire :\n';
        for (const contrat of gestionnaireContratsRef.current) {
          content += `• ${contrat.code_chantier}\n`;
        }
      }

      return (
        (!gestionnaireContratsRef.current?.length &&
          !referentTechniqueContratRef.current?.length) ||
        (await open({
          title: 'Contact décédé.e / non actif.ve',
          content,
        })) === 'confirm'
      );
    }

    return true;
  };

  const handleMultipleDialogs = useMultipleConfirmationDialog(
    handleDeleteCriticalActivitesDialog,
    handleDeadInactiveAccountDialog
  );

  const onFormSubmit = async () => {
    const areConfirmed = await handleMultipleDialogs();
    if (areConfirmed) {
      handleSubmit(onSubmit)();
    }
  };

  return (
    <Box elevation={3} component={Paper} sx={{ width: '100%', p: 3 }}>
      <Typography mb={2} variant='h5' component='div'>
        Édition contact
      </Typography>
      <form>
        <Grid container spacing={2} alignItems='center'>
          <Grid item xs={12}>
            <DividerTitle text={'Informations générales'} />
          </Grid>
        </Grid>
        <Grid container sx={{ mt: 1 }} spacing={2} alignItems='center'>
          <Grid item xs={12} sm={6} md={3}>
            <Controller
              name={'activites_list'}
              control={control}
              rules={{ required: 'Activité(s) requise(s).' }}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <Autocomplete
                  loading={!choices}
                  disablePortal
                  required={true}
                  multiple
                  onChange={(_, item) => onChange(item)}
                  getOptionLabel={(p) => (p ? p.nom : '')}
                  options={choices ? choices.activites : []}
                  defaultValue={item.activites_list}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      label='Activité(s) *'
                      error={!!error}
                      value={value ? value : ''}
                      helperText={error ? error.message : null}
                    />
                  )}
                />
              )}
            />
          </Grid>
        </Grid>
        <Grid container sx={{ mt: 1 }} spacing={2} alignItems='center'>
          <Grid item xs={12} sm={6} md={3}>
            <Controller
              name={'civilite'}
              control={control}
              render={({ field: { onChange, value } }) => (
                <FormControl>
                  <FormLabel>Civilité</FormLabel>
                  <RadioGroup
                    row
                    value={value ? value : ''}
                    onChange={onChange}
                  >
                    <FormControlLabel
                      value='Monsieur'
                      control={<Radio />}
                      label='Monsieur'
                    />
                    <FormControlLabel
                      value='Madame'
                      control={<Radio />}
                      label='Madame'
                    />
                  </RadioGroup>
                </FormControl>
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Controller
              name={'nom'}
              control={control}
              rules={{ maxLength: 256, required: 'Nom requis.' }}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  onChange={onChange}
                  value={value ? value : ''}
                  label={'Nom'}
                  inputProps={{ maxLength: 256 }}
                  fullWidth
                  required={true}
                  error={!!error}
                  helperText={error ? error.message : null}
                  onBlur={(e) => onChange(e.target.value.toUpperCase())}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Controller
              name={'prenom'}
              control={control}
              rules={{ maxLength: 256 }}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  onChange={onChange}
                  value={value ? value : ''}
                  label={'Prénom'}
                  inputProps={{ maxLength: 256 }}
                  fullWidth
                  error={!!error}
                  helperText={error ? error.message : null}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <DatePickerField
              name='date_naissance'
              control={control}
              label='Date de naissance'
            />
          </Grid>
        </Grid>
        <Grid container sx={{ mt: 1 }} spacing={2} alignItems='center'>
          <Grid item xs={12} sm={6} md={3}>
            <EntrepriseField
              control={control}
              fieldName={'entreprises_list'}
              defaultValue={item.entreprises_list}
              entreprises={entreprises}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Controller
              name={'profession'}
              control={control}
              rules={{ maxLength: 256 }}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  onChange={onChange}
                  value={value ? value : ''}
                  label={'Profession'}
                  inputProps={{ maxLength: 256 }}
                  fullWidth
                  error={!!error}
                  helperText={error ? error.message : null}
                />
              )}
            />
          </Grid>
        </Grid>
        <Grid container sx={{ mt: 1 }} spacing={2} alignItems='center'>
          <Grid item xs={12} sm={3} md={2}>
            <BaseTextField
              control={control}
              name='telephone_fixe'
              label='Téléphone fixe'
              maxLength={16}
              required={
                requiredMapper.telephone_fixe && 'Téléphone fixe requis.'
              }
            />
          </Grid>
          <Grid item xs={12} sm={3} md={2}>
            <BaseTextField
              control={control}
              name='telephone_portable'
              maxLength={16}
              required={
                requiredMapper.telephone_portable &&
                'Téléphone portable requis.'
              }
              label='Téléphone portable'
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <MailTextField
              control={control}
              name='email'
              required={requiredMapper.email && 'E-mail requis.'}
              maxLength={120}
              label='E-mail'
            />
          </Grid>
        </Grid>
        <Grid container sx={{ mt: 1 }} spacing={2} alignItems='center'>
          <Grid item xs={12} sm={5} md={5}>
            <AdresseField
              control={control}
              fieldName={'adresse'}
              relatedFiedlNames={{
                commune: 'commune',
                codePostal: 'code_postal',
                pays: 'pays',
              }}
              label={'Adresse'}
              setValue={setValue}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={3}>
            <Controller
              name={'commune'}
              control={control}
              rules={{ maxLength: 256 }}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  onChange={onChange}
                  value={value ? value : ''}
                  label={'Commune'}
                  inputProps={{ maxLength: 256 }}
                  fullWidth
                  error={!!error}
                  helperText={error ? error.message : null}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={3} md={2}>
            <Controller
              name={'code_postal'}
              control={control}
              rules={{
                maxLength: {
                  value: 5,
                  message: 'Le code postal doit avoir 5 caractères.',
                },
                minLength: {
                  value: 5,
                  message: 'Le code postal doit avoir 5 caractères.',
                },
              }}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  onChange={onChange}
                  value={value ? value : ''}
                  label={'Code postal'}
                  inputProps={{
                    maxLength: 5,
                    pattern: '[0-9]*',
                  }}
                  fullWidth
                  error={!!error}
                  helperText={error ? error.message : null}
                />
              )}
            />
          </Grid>
        </Grid>
        <Grid container sx={{ mt: 1 }} spacing={2} alignItems='center'>
          <Grid item xs={12} sm={6} md={3}>
            <Controller
              name={'departement'}
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <FormControl fullWidth>
                  <InputLabel id='dept-select-label'>Département</InputLabel>
                  <Select
                    labelId='dept-select-label'
                    id='dept-select'
                    value={value || ''}
                    label='Département'
                    onChange={onChange}
                    error={!!error}
                  >
                    {choices &&
                      choices.departements.map((val, idx) => (
                        <MenuItem key={idx} value={val}>
                          {val}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Controller
              name={'pays'}
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <Autocomplete
                  loading={!choices}
                  disablePortal
                  defaultValue={item.pays}
                  onChange={(_, val) => onChange(val)}
                  options={choices ? choices.pays : []}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      label='Pays'
                      error={!!error}
                      value={value ? value : ''}
                      helperText={error ? error.message : null}
                    />
                  )}
                />
              )}
            />
          </Grid>
        </Grid>
        <Grid container sx={{ mt: 1 }} spacing={2} alignItems='center'>
          <Grid item xs={12}>
            <DividerTitle text={'Relation Néosylva'} />
          </Grid>
        </Grid>
        <Grid container sx={{ mt: 1 }} spacing={2} alignItems='center'>
          <Grid item xs={12} sm={6} md={3}>
            <Controller
              name={'contact_neosylva'}
              control={control}
              rules={{
                maxLength: 50,
                required: 'Contact privilégié Néosylva requis.',
              }}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <FormControl fullWidth required={true}>
                  <InputLabel id='neosylva-select-label'>
                    Contact privilégié Néosylva
                  </InputLabel>
                  <Select
                    labelId='neosylva-select-label'
                    id='neosylva-select'
                    onChange={onChange}
                    value={value || ''}
                    label='Contact privilégié Néosylva'
                    error={!!error}
                    defaultValue={item.contact_neosylva}
                    renderValue={(value) =>
                      `${value.first_name} ${value.last_name}`
                    }
                  >
                    {usersList &&
                      usersList
                        .filter((user) =>
                          ['neosylva', 'neosylva_cmf'].includes(user.role.name)
                        )
                        .map((user) => (
                          <MenuItem key={user.username} value={user}>
                            {user.first_name} {user.last_name}
                          </MenuItem>
                        ))}
                  </Select>
                </FormControl>
              )}
            />
          </Grid>
        </Grid>
        <Grid container sx={{ mt: 1 }} spacing={2} alignItems='center'>
          <Grid item xs={12} sm={6} md={2}>
            <Controller
              name={'relation_contractuelle_neosylva'}
              control={control}
              render={({ field: { value, onChange } }) => (
                <FormControlLabel
                  control={
                    <Switch
                      checked={value}
                      onChange={(e) => onChange(e.target.checked)}
                    />
                  }
                  label='Relation contractuelle avec Néosylva'
                />
              )}
            />
          </Grid>
        </Grid>
        <Grid container sx={{ mt: 1 }} spacing={2} alignItems='center'>
          <Grid item xs={5} sm={3} md={2}>
            <Controller
              name={'newsletter'}
              control={control}
              render={({ field: { value, onChange } }) => (
                <FormControlLabel
                  control={
                    <Switch
                      checked={value}
                      onChange={(e) => onChange(e.target.checked)}
                    />
                  }
                  label='Newsletter'
                />
              )}
            />
          </Grid>
          <Grid item xs={7} sm={4} md={2}>
            <Controller
              name={'rendez_vous_annuel_pressenti'}
              control={control}
              render={({ field: { value, onChange } }) => (
                <FormControlLabel
                  control={
                    <Switch
                      checked={value}
                      onChange={(e) => onChange(e.target.checked)}
                    />
                  }
                  label='Rendez-vous annuel pressenti'
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={5} md={3}>
            <Controller
              name={'voeux'}
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <FormControl fullWidth>
                  <InputLabel id='voeux-select-label'>Vœux</InputLabel>
                  <Select
                    labelId='voeux-select-label'
                    id='voeux-select'
                    onChange={onChange}
                    value={value || ''}
                    label='Vœux'
                    error={!!error}
                    renderValue={(value) => value.nom}
                  >
                    {choices &&
                      choices.voeux_types.map((item) => (
                        <MenuItem key={item.id} value={item}>
                          {item.nom}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              )}
            />
          </Grid>
        </Grid>
        <Grid container sx={{ mt: 1 }} spacing={2} alignItems='center'>
          <Grid item xs={12} sm={12} md={3}>
            <Controller
              name={'commentaire'}
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  onChange={onChange}
                  value={value ? value : ''}
                  label={'Commentaire'}
                  fullWidth
                  error={!!error}
                  multiline
                  minRows={3}
                  helperText={error ? error.message : null}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <SwitchField
              name='decede_inactif'
              control={control}
              label='Décédé.e / non actif.ve'
            />
          </Grid>
        </Grid>
        <FormSubmitButtons
          onSubmit={onFormSubmit}
          submitLabel='Éditer'
          disabledSubmit={!isDirty || !isValid || isFetching}
          onCancel={onCancel}
        />
      </form>
    </Box>
  );
}

ContactForm.propTypes = {
  item: PropTypes.object.isRequired,
};
