import React from 'react';

import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

import FormSubmitButtons from '@/features/shared/FormSubmitButtons';

import useChoices from '@/hooks/useChoices';
import useFormItem from '@/hooks/useFormItem';
import BaseTextField from '@/components/controlled-fields/text-fields/BaseTextField';
import SelectField from '@/components/controlled-fields/SelectField';

export default function OrientationsGestionForm({ item }) {
  const itemType = 'sylvalibre_orientations_gestion';

  const choices = useChoices(itemType);

  const { onCancel, onSubmit } = useFormItem(item.id, itemType);

  const {
    handleSubmit,
    control,
    setValue,
    formState: { isDirty, isValid },
  } = useForm({
    defaultValues: item,
    reValidateMode: 'onBlur',
    mode: 'onChange',
  });

  return (
    <Box elevation={3} component={Paper} sx={{ width: '100%', p: 3 }}>
      <Typography mb={2} variant='h5' component='div'>
        {item.id ? 'Édition' : 'Création'} correspondance Sylvalibre :
        Orientations de gestion
      </Typography>
      <form>
        <Grid container spacing={2} alignItems='center'>
          <Grid container item spacing={2}>
            <Grid item xs={12} sm={6} md={4}>
              <BaseTextField
                control={control}
                name='legende'
                label='Nom de la légende'
                required='Nom de la légende requis.'
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <SelectField
                control={control}
                name='peuplement_objectif_type'
                baseId='type-pplt'
                label='Peuplement objectif'
                values={choices?.peuplements_objectifs_types}
                renderValue={(value) => value?.nom}
                itemMapper={(type_pplt) => ({
                  key: type_pplt.code,
                  label: type_pplt.nom,
                })}
                onClear={() => {
                  setValue('peuplement_objectif_type', null, {
                    shouldValidate: true,
                  });
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <SelectField
                control={control}
                name='type_traitement'
                label='Type de traitement'
                renderValue={(value) => value?.nom}
                values={choices?.types_traitements}
                itemMapper={(type) => ({
                  key: type.id,
                  label: type.nom,
                })}
                onClear={() => {
                  setValue('type_traitement', null, { shouldValidate: true });
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <SelectField
                control={control}
                name='essence_principale'
                label='Essence principale'
                renderValue={(value) => value?.nom_vernaculaire}
                values={choices?.essences}
                itemMapper={(type) => ({
                  key: type.id,
                  label: type.nom_vernaculaire,
                })}
                const
                onClear={() => {
                  setValue('essence_principale', null, { shouldValidate: true });
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        <FormSubmitButtons
          onSubmit={handleSubmit(onSubmit)}
          submitLabel={item.id ? 'Éditer' : 'Créer'}
          disabledSubmit={!isDirty || !isValid}
          onCancel={onCancel}
        />
      </form>
    </Box>
  );
}

OrientationsGestionForm.propTypes = {
  item: PropTypes.object.isRequired,
};
