import { useMemo } from 'react';

export default function useIsSylvalibreSynced(unitesGestionList) {
  return useMemo(
    () =>
      unitesGestionList?.some(
        (ug) => ug.sylvalibre_ug_uuid && ug.sylvalibre_latest_sync_datetime
      ) ?? false,
    [unitesGestionList]
  );
}
