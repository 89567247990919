import React, { useEffect } from 'react';

import PropTypes from 'prop-types';

import useSetFormValues from '@/hooks/useSetFormValues';
import useIsSylvalibreSynced from '@/hooks/useIsSylvalibreSynced';

import AreaTextField from '@/components/controlled-fields/text-fields/AreaTextField';
import MultilineTextField from '@/components/controlled-fields/text-fields/MultilineTextField';
import TabPanelForm from '@/components/TabPanelForm';

import DocumentTable from '@/features/shared/document/DocumentTable';
import UniteGestionTable from './unite-gestion/UniteGestionTable';

import Grid from '@mui/material/Grid';

export default function ProjetNeosylvaTab({
  form,
  tabValue,
  unitesGestionList,
  stationsList,
  documentsList,
  propriete,
  contratProprietaire,
}) {
  const isSylvalibreSynced = useIsSylvalibreSynced(unitesGestionList);

  const { control, setValue } = form;
  const setFormValues = useSetFormValues();

  const contratProprietaireFields = [
    ['surface_pressentie_sous_contrat', false, setValue],
    ['points_vigilance', false, setValue],
  ];

  useEffect(() => {
    if (!contratProprietaire) {
      return;
    }

    setFormValues(
      contratProprietaireFields,
      contratProprietaire,
      'contrat_proprietaire'
    );
  }, [contratProprietaire]);

  return (
    <TabPanelForm currentValue={tabValue} index={4}>
      <form>
        {propriete && (
          <Grid container spacing={2} alignItems='center'>
            <Grid item xs={12}>
              <UniteGestionTable
                items={unitesGestionList}
                stationsList={stationsList}
              />
            </Grid>
          </Grid>
        )}
        {contratProprietaire && (
          <Grid container spacing={2} alignItems='center'>
            <Grid item xs={12} sm={6} md={3}>
              <AreaTextField
                control={control}
                name='contrat_proprietaire.surface_pressentie_sous_contrat'
                label='Surface totale pressentie sous contrat (ha)'
                disabled={isSylvalibreSynced}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <MultilineTextField
                control={control}
                name='contrat_proprietaire.points_vigilance'
                label='Éléments pouvant conditionner la faisabilité du projet'
              />
            </Grid>
          </Grid>
        )}
        {propriete && (
          <Grid container spacing={2} alignItems='center' sx={{ mt: 1 }}>
            <Grid item xs={12}>
              <DocumentTable
                items={documentsList}
                title={'Documents'}
                contratProprietaire={contratProprietaire}
                enableUpload
              />
            </Grid>
          </Grid>
        )}
      </form>
    </TabPanelForm>
  );
}

ProjetNeosylvaTab.propTypes = {
  form: PropTypes.object.isRequired,
  tabValue: PropTypes.number.isRequired,
  unitesGestionList: PropTypes.array,
  stationsList: PropTypes.array,
  documentsList: PropTypes.array,
  propriete: PropTypes.object,
  contratProprietaire: PropTypes.object,
};
