import React, { useEffect, useMemo, useState } from 'react';

import deepmerge from 'deepmerge';

import PropTypes from 'prop-types';

import { useForm, useWatch } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';

import useChoices from '@/hooks/useChoices';
import useFetchFilteredItems from '@/hooks/useFetchFilteredItems';
import useGoBackHistory from '@/hooks/useGoBackHistory';
import useOnMount from '@/hooks/useOnMount';
import useOnUnmount from '@/hooks/useOnUnmount';
import useSetFormValues from '@/hooks/useSetFormValues';
import useConfirmationDialog from '@/hooks/useConfirmationDialog';
import useTabState from '@/hooks/useTabState';

import FormSubmitButtons from '@/features/shared/FormSubmitButtons';
import { setIsLoading } from '@/slices/isLoadingSlice';
import { setFilteredItems, deleteItem } from '@/slices/itemSlice';
import { setMessage } from '@/slices/messageSlice';
import { createFileFromUrl } from '@/utils/file';

import APIService from '@/APIService';

import RenseignementsGenerauxTab from './RenseignementsGenerauxTab';
import SuiviAvancementTab from './SuiviAvancementTab';
import SuiviEconomiqueTab from './SuiviEconomiqueTab';
import SuiviTechniqueTab from './SuiviTechniqueTab';
import SynchronisationSylvalibreTab from './SynchronisationSylvalibreTab';
import useIsSylvalibreSynced from '@/hooks/useIsSylvalibreSynced';

export default function FicheContratForm({
  proprieteId,
  contratId,
  contratProprietaire,
  defaultTab,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const goBackHistory = useGoBackHistory();
  const setFormValues = useSetFormValues();
  const fetchFilteredItems = useFetchFilteredItems();

  const [propriete, setPropriete] = useState(null);

  const [tabValue, handleTabChange] = useTabState(defaultTab);

  const [codesChantierList, setCodesChantierList] = useState([]);

  const unitesGestionList = useSelector(
    (store) => store.itemReducer.filteredItems.unites_gestion
  );

  const parcellesCadastralesSousContratList = useSelector(
    (store) =>
      store.itemReducer.filteredItems.parcelles_cadastrales_sous_contrat
  );

  const parcellesCadastralesPressentiesSousContratList = useSelector(
    (store) =>
      store.itemReducer.filteredItems
        .parcelles_cadastrales_pressenties_sous_contrat
  );

  const [signatureSeingPrive, setSignatureSeingPrive] = useState({
    code_chantier: contratProprietaire.code_chantier,
    date_signature_contrat_seing_prive:
      contratProprietaire.date_signature_contrat_seing_prive,
    montant_forfaitaire: contratProprietaire.montant_forfaitaire,
    montant_total_previsionnel_operations:
      contratProprietaire.montant_total_previsionnel_operations,
    pourcentage_services_environnementaux:
      contratProprietaire.pourcentage_services_environnementaux,
  });
  const [signatureActeNotarie, setSignatureActeNotarie] = useState({
    date_signature_acte_notarie:
      contratProprietaire.date_signature_acte_notarie,
  });
  const [motifRupture, setMotifRupture] = useState({
    motif_rupture: contratProprietaire.motif_rupture,
  });

  const choices = useChoices('fiche_contrat');

  const proprietairesList = useSelector(
    (store) => store.itemReducer.filteredItems.proprietaires
  );
  const isFetching = useSelector(
    (store) => store.itemReducer.pendingActions !== 0
  );
  const documentsList = useSelector(
    (store) => store.itemReducer.filteredItems.documents
  );

  const form1 = useForm({
    defaultValues: {
      propriete: {
        nom_commun: null,
        adresse: null,
        commune: null,
        gestionnaire: null,
        code_postal: null,
        departement: null,
        surface_totale_propriete: null,
      },
      contrat_proprietaire: {
        code_chantier: null,
        referent_technique: null,
      },
    },
    reValidateMode: 'onBlur',
    mode: 'onChange',
  });
  const {
    control: control1,
    setValue: setValue1,
    getValues: getValues1,
    formState: { isValid: isValid1 },
  } = form1;

  const form2 = useForm({
    defaultValues: {
      contrat_proprietaire: {
        etat: null,
        date_signature_contrat_seing_prive: null,
        montant_forfaitaire: null,
        montant_total_previsionnel_operations: null,
        pourcentage_services_environnementaux: null,
        date_signature_acte_notarie: null,
        motif_rupture: null,
      },
    },
    reValidateMode: 'onBlur',
    mode: 'onChange',
  });
  const { setValue: setValue2, getValues: getValues2 } = form2;

  const [codeChantierWatch, nomProprieteWatch] = useWatch({
    control: control1,
    name: ['contrat_proprietaire.code_chantier', 'propriete.nom_commun'],
  });

  const contratProprietaireFields = [
    ['referent_technique', true, setValue1],
    ['surface_pressentie_sous_contrat', true, setValue1],
    ['code_chantier', true, setValue1],
    ['etat', true, setValue2],
    ['date_signature_contrat_seing_prive', true, setValue2],
    ['montant_forfaitaire', true, setValue2],
    ['montant_total_previsionnel_operations', true, setValue2],
    ['pourcentage_services_environnementaux', true, setValue2],
    ['date_signature_acte_notarie', true, setValue2],
  ];
  const proprieteFields = [
    ['nom_commun', true, setValue1],
    ['adresse', true, setValue1],
    ['commune', true, setValue1],
    ['gestionnaire', true, setValue1],
    ['code_postal', true, setValue1],
    ['departement', true, setValue1],
    ['surface_totale_propriete', true, setValue1],
  ];
  const signatureSousSeingPriveFields = [
    ['code_chantier', true, setValue1],
    ['date_signature_contrat_seing_prive', true, setValue2],
    ['montant_forfaitaire', true, setValue2],
    ['montant_total_previsionnel_operations', true, setValue2],
    ['pourcentage_services_environnementaux', true, setValue2],
  ];
  const signatureActeNotarieFields = [
    ['date_signature_acte_notarie', true, setValue2],
  ];

  const motifRuptureFields = [['motif_rupture', true, setValue2]];

  const disabledSubmit = useMemo(
    () => !isValid1 || isFetching || proprietairesList?.length === 0,
    [isValid1, isFetching, proprietairesList]
  );

  useEffect(() => {
    if (signatureSeingPrive) {
      setFormValues(
        signatureSousSeingPriveFields,
        signatureSeingPrive,
        'contrat_proprietaire'
      );
    }

    if (signatureActeNotarie) {
      setFormValues(
        signatureActeNotarieFields,
        signatureActeNotarie,
        'contrat_proprietaire'
      );
    }

    if (motifRupture) {
      setFormValues(motifRuptureFields, motifRupture, 'contrat_proprietaire');
    }
  }, [signatureSeingPrive, signatureActeNotarie, motifRupture]);

  useEffect(() => {
    if (
      !codeChantierWatch ||
      codeChantierWatch === signatureSeingPrive?.code_chantier
    ) {
      return;
    }

    setSignatureSeingPrive({
      ...signatureSeingPrive,
      code_chantier: codeChantierWatch,
    });
  }, [codeChantierWatch]);

  const fetchProprieteRelatedItems = () => {
    fetchFilteredItems({
      filter: { propriete_id: propriete.id },
      itemTypes: ['stations', 'parcelles_cadastrales_pressenties_sous_contrat'],
    });
  };

  const fetchContratRelatedItems = (contratProprietaireId) => {
    dispatch(
      setFilteredItems({
        itemsList: null,
        itemType: 'unites_gestion',
      })
    );

    fetchFilteredItems({
      filter: {
        item_type: 'contrat_proprietaire',
        item_id: contratProprietaireId,
      },
      itemTypes: [
        'proprietaires',
        'unites_gestion',
        'parcelles_cadastrales_sous_contrat',
      ],
    });
  };

  const isSylvalibreSynced = useIsSylvalibreSynced(unitesGestionList);

  useEffect(() => {
    if (!contratProprietaire) {
      return;
    }

    setValue1(
      'contrat_proprietaire.surface_pressentie_sous_contrat',
      contratProprietaire.surface_pressentie_sous_contrat,
      {
        shouldValidate: true,
      }
    );
  }, [contratProprietaire?.surface_pressentie_sous_contrat]);

  useEffect(() => {
    if (!contratProprietaire.id) {
      return;
    }

    setFormValues(
      contratProprietaireFields,
      contratProprietaire,
      'contrat_proprietaire'
    );

    fetchContratRelatedItems(contratProprietaire.id);

    dispatch(
      setFilteredItems({
        itemsList: contratProprietaire.sharepoints_list.concat(
          contratProprietaire.files_list
        ),
        itemType: 'documents',
      })
    );
  }, [contratProprietaire?.id]);

  useEffect(() => {
    if (!propriete) {
      return;
    }

    setFormValues(proprieteFields, propriete, 'propriete');

    if (!contratId) {
      fetchFilteredItems({
        filter: { item_type: 'propriete', item_id: propriete.id },
        itemTypes: ['proprietaires'],
      });
      dispatch(
        setFilteredItems({
          itemsList: [],
          itemType: 'unites_gestion',
        })
      );
    }

    fetchProprieteRelatedItems();
  }, [propriete?.id]);

  const onSubmit = async (refresh = true) => {
    const formValuesList = [getValues1(), getValues2()];

    const formValues = deepmerge.all(formValuesList);
    formValues.contrat_proprietaire = {
      ...contratProprietaire,
      ...formValues.contrat_proprietaire,
      id: contratProprietaire?.id,
      propriete,
      unites_gestion_list: unitesGestionList.map((ug) => ({
        ...ug,
        propriete,
        contrat_proprietaire: contratProprietaire,
      })),
    };
    formValues.proprietaires_list = proprietairesList;
    formValues.propriete = {
      ...propriete,
      ...formValues.propriete,
    };
    formValues.unites_gestion_list = unitesGestionList.map((ug) => ({
      ...ug,
      contrat_proprietaire: contratProprietaire,
    }));
    formValues.parcelles_cadastrales_sous_contrat_list =
      parcellesCadastralesSousContratList?.map((parcelle) => ({
        ...parcelle,
        contrat_proprietaire: contratProprietaire,
      }));
    formValues.parcelles_cadastrales_pressenties_sous_contrat_list =
      parcellesCadastralesPressentiesSousContratList;

    const formData = new FormData();
    const filesList = [];
    const sharepointsList = [];
    documentsList.forEach((document) =>
      (document.repository ? sharepointsList : filesList).push(document)
    );
    formValues.files_list = filesList;
    formValues.sharepoints_list = sharepointsList;

    for (const file of filesList) {
      if (file.local_url && file.temp_uuid) {
        formData.append(
          'files',
          await createFileFromUrl(file.local_url, file.temp_uuid)
        );
      }
    }

    formData.append('body', JSON.stringify(formValues));

    const requestParams = {
      url: contratId ? `fiche_contrat/${contratId}` : 'fiche_contrat',
      data: formData,
      onError: (res) => {
        const message = res.data.message || res.data.detail;
        if (message) {
          dispatch(setMessage(message));
        }
      },
    };

    const requestMethod = contratId ? 'patch' : 'post';

    return await APIService[requestMethod](requestParams)
      .then(({ data: { contrat_id, message } }) => {
        if (requestMethod === 'post') {
          navigate(
            `/fiche-contrat/${contrat_id}?propriete_id=${proprieteId}&tab=${tabValue}`,
            { replace: true }
          );
        } else if (refresh) {
          fetchContratRelatedItems(contratId);
          fetchProprieteRelatedItems();
        }
        dispatch(setMessage(message));
        return contrat_id;
      })
      .finally(() => dispatch(setIsLoading(false)));
  };

  useOnMount(() => {
    APIService.get({
      url: `fiche_contrat/code_chantier/${proprieteId}`,
    }).then(({ data: { code_chantier, code_chantier_list } }) => {
      if (!contratId) {
        setValue1('contrat_proprietaire.code_chantier', code_chantier, {
          shouldValidate: true,
        });
        dispatch(
          setFilteredItems({
            itemsList: [],
            itemType: 'documents',
          })
        );
      } else {
        code_chantier_list = code_chantier_list.filter(
          (codeChantier) => codeChantier !== contratProprietaire.code_chantier
        );
      }
      setCodesChantierList(code_chantier_list);
    });
    APIService.get({
      url: `proprietes/${proprieteId}`,
    }).then(({ data }) => setPropriete(data));
  });

  useOnUnmount(() => {
    documentsList
      ?.filter((document) => !document.repository)
      .forEach((file) => URL.revokeObjectURL(file.local_url));
  });

  const { open } = useConfirmationDialog();

  const deleteContrat = async () => {
    const result = await open({
      title: 'Suppression',
      content: `Confirmer la suppression du contrat : ${codeChantierWatch} ?`,
    });

    if (result === 'confirm') {
      dispatch(
        deleteItem({ itemId: contratId, itemType: 'contrats_proprietaires' })
      );
      goBackHistory();
    }
  };

  const redirectTo = async (path, opts) => {
    const result = await open({
      content: 'Voulez-vous sauvegarder avant de quitter la fiche contrat ?',
      confirmButton: 'Oui',
      alternativeButton: 'Non',
    });

    if (result === 'confirm') {
      dispatch(setIsLoading(true));
      if (disabledSubmit) {
        dispatch(
          setMessage(
            'Sauvegarde impossible, veuillez remplir les champs obligatoires de la fiche contrat.'
          )
        );
        dispatch(setIsLoading(false));
        return;
      }
      onSubmit(false)
        .then(() => {
          navigate(path, opts);
        })
        .finally(() => dispatch(setIsLoading(false)));
    } else if (result === 'alternative') {
      navigate(path, opts);
    }
  };

  const redirectToTableauDeBordCMF = () => {
    redirectTo('/tableau-bord-cmf');
  };

  const redirectToFichePropriete = () => {
    redirectTo(`/fiche-propriete/${proprieteId}`, { replace: true });
  };

  return (
    <Box elevation={3} component={Paper} sx={{ width: '100%', p: 3 }}>
      <Breadcrumbs aria-label='breadcrumb'>
        <Link
          underline='hover'
          color='inherit'
          onClick={redirectToTableauDeBordCMF}
        >
          Tableau de bord CMF
        </Link>
        <Link
          underline='hover'
          color='inherit'
          onClick={redirectToFichePropriete}
        >
          Propriété {nomProprieteWatch}
        </Link>
        <Typography sx={{ color: 'text.primary' }}>
          Contrat {codeChantierWatch}
        </Typography>
      </Breadcrumbs>
      <Box
        mt={1}
        mb={2}
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography variant='h5' component='div'>
          {contratId ? 'Édition' : 'Création'} fiche contrat
        </Typography>
        {contratId && (
          <Button
            onClick={deleteContrat}
            variant='outlined'
            startIcon={<DeleteIcon />}
          >
            Supprimer
          </Button>
        )}
      </Box>
      <Tabs
        value={tabValue}
        onChange={handleTabChange}
        variant='scrollable'
        scrollButtons
        allowScrollButtonsMobile
      >
        <Tab label='Renseignements généraux' />
        <Tab label="Suivi de l'avancement" />
        <Tab label='Synchronisation Sylvalibre' />
        <Tab label='Suivi économique' />
        <Tab label='Suivi technique' />
      </Tabs>
      <RenseignementsGenerauxTab
        tabValue={tabValue}
        form={form1}
        choices={choices}
        proprietairesList={proprietairesList}
        codesChantierList={codesChantierList}
        isSylvalibreSynced={isSylvalibreSynced}
      />
      <SuiviAvancementTab
        tabValue={tabValue}
        form={form2}
        choices={choices}
        onSubmit={onSubmit}
        disabledSubmit={disabledSubmit}
        contratId={contratId}
        documentsList={documentsList}
        contratProprietaire={contratProprietaire}
        codesChantierList={codesChantierList}
        signatureSeingPriveState={[signatureSeingPrive, setSignatureSeingPrive]}
        signatureActeNotarieState={[
          signatureActeNotarie,
          setSignatureActeNotarie,
        ]}
        motifRuptureState={[motifRupture, setMotifRupture]}
        unitesGestionList={unitesGestionList}
      />
      <SynchronisationSylvalibreTab
        tabValue={tabValue}
        unitesGestionList={unitesGestionList}
        parcellesCadastralesSousContratList={
          parcellesCadastralesSousContratList
        }
        parcellesCadastralesPressentiesSousContratList={
          parcellesCadastralesPressentiesSousContratList
        }
        propriete={propriete}
        contratProprietaire={contratProprietaire}
        isSylvalibreSynced={isSylvalibreSynced}
      />
      <SuiviEconomiqueTab tabValue={tabValue} />
      <SuiviTechniqueTab tabValue={tabValue} />
      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <FormSubmitButtons
          onSubmit={onSubmit}
          submitLabel={'Sauvegarder'}
          onCancel={goBackHistory}
          disabledSubmit={disabledSubmit}
        />
      </Box>
    </Box>
  );
}

FicheContratForm.propTypes = {
  contratProprietaire: PropTypes.object.isRequired,
  contratId: PropTypes.string,
  proprieteId: PropTypes.string.isRequired,
  defaultTab: PropTypes.string,
};
