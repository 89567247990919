import React, { useEffect } from 'react';

import PropTypes from 'prop-types';
import { useWatch } from 'react-hook-form';
import { useSelector } from 'react-redux';

import useFetchFilteredItems from '@/hooks/useFetchFilteredItems';

import Grid from '@mui/material/Grid';

import TabPanelForm from '@/components/TabPanelForm';
import DividerTitle from '@/components/DividerTitle';

import ProprietaireTable from '@/features/shared/proprietaire/ProprietaireTable';
import ContratProprietaireAutocompleteField from '@/features/shared/ContratProprietaireAutocompleteField';
import ReferentTechniqueFormDialog from '@/features/shared/referent-technique/ReferentTechniqueFormDialog';

export default function RenseignementProprietaireTab({
  edition,
  tabValue,
  contratProprietaireState,
  contratId,
  form,
  proprietairesList,
}) {
  const fetchFilteredItems = useFetchFilteredItems();

  const { contratProprietaire, setContratProprietaire } =
    contratProprietaireState;

  const { control, setValue } = form;

  const referentTechniqueWatch = useWatch({
    control,
    name: 'contrat_proprietaire.referent_technique',
  });

  const contratsProprietaires =
    edition &&
    useSelector(
      (store) => store.itemReducer.filteredItems.contrats_proprietaires
    );

  useEffect(() => {
    if (!edition || contratsProprietaires) {
      return;
    }

    fetchFilteredItems({
      filter: { item_type: 'diagnostic' },
      itemTypes: ['contrats_proprietaires'],
    });
  }, [contratsProprietaires]);

  return (
    <TabPanelForm currentValue={tabValue} index={0}>
      <form>
        {edition && !contratId && (
          <Grid container spacing={2} alignItems='center'>
            <Grid item xs={12} sm={6} md={3}>
              <ContratProprietaireAutocompleteField
                currentContratProprietaire={contratProprietaire}
                setContratProprietaire={setContratProprietaire}
                contratsProprietaires={contratsProprietaires}
              />
            </Grid>
          </Grid>
        )}
        {(edition ? contratProprietaire : true) && (
          <>
            <Grid container item spacing={2} mt={1}>
              <Grid item xs={12}>
                <ProprietaireTable items={proprietairesList} />
              </Grid>
            </Grid>
            <Grid container item spacing={2}>
              <Grid item xs={12}>
                <DividerTitle text='Référent technique' />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <ReferentTechniqueFormDialog
                  item={referentTechniqueWatch}
                  onSubmit={(value) =>
                    setValue('contrat_proprietaire.referent_technique', value)
                  }
                  proprietairesContactIds={proprietairesList?.map(
                    (item) => item.contact.id
                  )}
                />
              </Grid>
            </Grid>
          </>
        )}
      </form>
    </TabPanelForm>
  );
}

RenseignementProprietaireTab.propTypes = {
  edition: PropTypes.bool,
  tabValue: PropTypes.number,
  contratProprietaireState: PropTypes.object.isRequired,
  contratId: PropTypes.string,
  form: PropTypes.object,
  choices: PropTypes.object,
  proprietairesList: PropTypes.array,
};
