import React from 'react';

import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

import FormSubmitButtons from '@/features/shared/FormSubmitButtons';

import useChoices from '@/hooks/useChoices';
import useFormItem from '@/hooks/useFormItem';

import BaseTextField from '@/components/controlled-fields/text-fields/BaseTextField';
import SelectField from '@/components/controlled-fields/SelectField';

export default function CouvertExistantForm({ item }) {
  const itemType = 'sylvalibre_couvert_existant';

  const choices = useChoices(itemType);

  const { onCancel, onSubmit } = useFormItem(item.id, itemType);

  const {
    handleSubmit,
    control,
    setValue,
    formState: { isDirty, isValid },
  } = useForm({
    defaultValues: item,
    reValidateMode: 'onBlur',
    mode: 'onChange',
  });

  return (
    <Box elevation={3} component={Paper} sx={{ width: '100%', p: 3 }}>
      <Typography mb={2} variant='h5' component='div'>
        {item.id ? 'Édition' : 'Création'} correspondance Sylvalibre : Couvert
        existant
      </Typography>
      <form>
        <Grid container spacing={2} alignItems='center'>
          <Grid container item spacing={2}>
            <Grid item xs={12} sm={6} md={4}>
              <BaseTextField
                control={control}
                name='legende'
                label='Nom de la légende'
                required='Nom de la légende requis.'
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <SelectField
                control={control}
                name='peuplement_initial_type'
                baseId='type-pplt'
                label='Type de peuplement'
                values={choices?.peuplements_initiaux_types}
                renderValue={(value) => value?.nom}
                itemMapper={(type_peuplement) => ({
                  key: type_peuplement.code,
                  label: type_peuplement.nom,
                })}
                onClear={() => setValue('peuplement_initial_type', null)}
              />
            </Grid>
          </Grid>
        </Grid>
        <FormSubmitButtons
          onSubmit={handleSubmit(onSubmit)}
          submitLabel={item.id ? 'Éditer' : 'Créer'}
          disabledSubmit={!isDirty || !isValid}
          onCancel={onCancel}
        />
      </form>
    </Box>
  );
}

CouvertExistantForm.propTypes = {
  item: PropTypes.object.isRequired,
};
