import React from 'react';

import PropTypes from 'prop-types';

import Box from '@mui/material/Box';

export default function SylvalibreLinkCell({ row }) {
  return (
    <Box
      sx={{
        backgroundColor: row.sylvalibre_ug_uuid
          ? 'primary.main'
          : 'secondary.main',
        color: 'white',
        borderRadius: 0.75,
        textAlign: 'center',
      }}
    >
      {row.sylvalibre_ug_uuid ? 'Établi' : 'À établir'}
    </Box>
  );
}

SylvalibreLinkCell.propTypes = {
  row: PropTypes.object.isRequired,
};
