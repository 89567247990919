import React, { useCallback } from 'react';

import PropTypes from 'prop-types';

import { useWatch } from 'react-hook-form';

import Grid from '@mui/material/Grid';

import useItems from '@/hooks/useItems';

import AreaTextField from '@/components/controlled-fields/text-fields/AreaTextField';
import BaseTextField from '@/components/controlled-fields/text-fields/BaseTextField';
import CodePostalTextField from '@/components/controlled-fields/text-fields/CodePostalTextField';
import DividerTitle from '@/components/DividerTitle';
import TabPanelForm from '@/components/TabPanelForm';

import DepartementField from '@/features/shared/DepartementField';
import GestionnaireAutocompleteField from '@/features/shared/GestionnaireAutocompleteField';
import ProprietaireTable from '@/features/shared/proprietaire/ProprietaireTable';
import ReferentTechniqueFormDialog from '@/features/shared/referent-technique/ReferentTechniqueFormDialog';
import AdresseField from '@/features/shared/AdresseField';

export default function RenseignementsGenerauxTab({
  tabValue,
  form,
  choices,
  proprietairesList,
  codesChantierList,
  isSylvalibreSynced,
}) {
  const { control, setValue } = form;
  const gestionnaires = useItems('gestionnaires_pro');

  const referentTechniqueWatch = useWatch({
    control,
    name: 'contrat_proprietaire.referent_technique',
  });

  const validateCodeChantier = useCallback(
    (codeChantier) => {
      if (codesChantierList.includes(codeChantier)) {
        return 'Ce code chantier existe déjà.';
      }
      return true;
    },
    [codesChantierList]
  );

  return (
    <TabPanelForm currentValue={tabValue} index={0}>
      <form>
        <Grid container spacing={2}>
          <Grid container item spacing={2}>
            <Grid item xs={12} md={8} lg={5}>
              <BaseTextField
                control={control}
                name='contrat_proprietaire.code_chantier'
                label='Nom du contrat'
                required='Nom du contrat requis.'
                validate={validateCodeChantier}
              />
            </Grid>
          </Grid>
          <Grid container item spacing={2}>
            <Grid item xs={12}>
              <DividerTitle text='Informations propriété' />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <BaseTextField
                control={control}
                name='propriete.nom_commun'
                label='Nom de la propriété'
              />
            </Grid>
            <Grid item xs={12} sm={6} md={5}>
              <AdresseField
                control={control}
                fieldName='propriete.adresse'
                relatedFiedlNames={{
                  commune: 'propriete.commune',
                  codePostal: 'propriete.code_postal',
                }}
                label='Adresse propriété'
                setValue={setValue}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <BaseTextField
                control={control}
                name='propriete.commune'
                label='Commune propriété'
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <GestionnaireAutocompleteField
                control={control}
                name='propriete.gestionnaire'
                verboseName='Gestionnaire forestier pressenti'
                gestionnaires={gestionnaires}
                setValue={setValue}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <CodePostalTextField
                control={control}
                name='propriete.code_postal'
                label='Code postal propriété'
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <DepartementField
                control={control}
                name='propriete.departement'
                label='Département propriété'
                departementFieldName='propriete.departement'
                codePostalFieldName='propriete.code_postal'
                departements={choices?.departements}
                setValue={setValue}
              />
            </Grid>
          </Grid>
          <Grid container item spacing={2}>
            <Grid item xs={12} sm={6} md={3}>
              <AreaTextField
                control={control}
                name='propriete.surface_totale_propriete'
                label='Surface totale propriété (ha)'
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <AreaTextField
                control={control}
                name='contrat_proprietaire.surface_pressentie_sous_contrat'
                label='Surface sous contrat (ha)'
                disabled={isSylvalibreSynced}
              />
            </Grid>
          </Grid>
          <Grid container item spacing={2}>
            <Grid item xs={12}>
              <DividerTitle text='Informations signataires' />
            </Grid>
            <Grid item xs={12}>
              <ProprietaireTable items={proprietairesList} />
            </Grid>
          </Grid>
          <Grid container item spacing={2}>
            <Grid item xs={12}>
              <DividerTitle text='Référent technique' />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <ReferentTechniqueFormDialog
                item={referentTechniqueWatch}
                onSubmit={(value) =>
                  setValue('contrat_proprietaire.referent_technique', value)
                }
                proprietairesContactIds={proprietairesList?.map((item) => item.contact.id)}
              />
            </Grid>
          </Grid>
        </Grid>
      </form>
    </TabPanelForm>
  );
}

RenseignementsGenerauxTab.propTypes = {
  tabValue: PropTypes.number.isRequired,
  form: PropTypes.object.isRequired,
  choices: PropTypes.object,
  proprietairesList: PropTypes.array,
  codesChantierList: PropTypes.array.isRequired,
  isSylvalibreSynced: PropTypes.bool,
};
