import React from 'react';

import PropTypes from 'prop-types';

import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';

import ProprietesGestionTab from '@/features/tableau-bord-cmf/tabs/ProprietesGestionTab';
import ProprietesProspectionTab from '@/features/tableau-bord-cmf/tabs/ProprietesProspectionTab';
import ProprietesTab from '@/features/tableau-bord-cmf/tabs/ProprietesTab';

export default function TableauBordCMFGrids({
  tabValue,
  handleTabChange,
  proprietes,
  proprietesGestion,
  proprietesProspection,
  ...props
}) {
  return (
    <>
      <Tabs
        value={tabValue}
        onChange={handleTabChange}
        variant='scrollable'
        allowScrollButtonsMobile
      >
        <Tab label='Toutes les propriétés' />
        <Tab label='Propriétés en prospection' />
        <Tab label='Propriétés en gestion' />
      </Tabs>
      <ProprietesTab
        items={proprietes}
        tabValue={tabValue}
        {...props}
      />
      <ProprietesProspectionTab
        items={proprietesProspection}
        tabValue={tabValue}
        {...props}
      />
      <ProprietesGestionTab
        items={proprietesGestion}
        tabValue={tabValue}
        {...props}
      />
    </>
  );
}

TableauBordCMFGrids.propTypes = {
  tabValue: PropTypes.number.isRequired,
  handleTabChange: PropTypes.func.isRequired,
  proprietes: PropTypes.array,
  proprietesGestion: PropTypes.array,
  proprietesProspection: PropTypes.array,
};
