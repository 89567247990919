import React, { useState, useRef, useEffect, useMemo } from 'react';

import PropTypes from 'prop-types';

import { useDispatch, useSelector } from 'react-redux';

import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import ErrorIcon from '@mui/icons-material/Error';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import useConfirmationDialog from '@/hooks/useConfirmationDialog';
import useOnMount from '@/hooks/useOnMount';
import useDebounce from '@/hooks/useDebounce';
import {
  clearProprieteLink,
  fetchProprieteLink,
  removeProprieteLink,
  setProprieteLink,
} from '@/slices/sylvalibreSlice';
import useOnUnmount from '@/hooks/useOnUnmount';

export default function SylvalibreProprieteLink({ propriete, onClear }) {
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);

  const [error, setError] = useState('');

  const [searchTerm, setSearchTerm] = useState('');

  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  const proprieteLink = useSelector(
    (state) => state.sylvalibreReducer.proprieteLink
  );

  useOnMount(() => {
    if (!propriete?.sylvalibre_folder_uuid || proprieteLink) {
      return;
    }

    dispatch(fetchProprieteLink({ proprieteId: propriete?.id }));
  });

  useOnUnmount(() => dispatch(clearProprieteLink()));

  const linkInputRef = useRef(null);

  const { open } = useConfirmationDialog();

  const onClearIconClick = async () => {
    if (!proprieteLink) {
      if (linkInputRef.current) {
        linkInputRef.current.value = '';
      }
      setError('');
      setSearchTerm('');
      return;
    }

    const result = await open({
      content: 'Confirmer la suppression du lien vers le dossier Sylvalibre ?',
    });

    if (result === 'confirm') {
      dispatch(
        removeProprieteLink({
          proprieteId: propriete?.id,
          setIsLoading,
          setError,
          onSuccess: () => {
            setSearchTerm('');

            if (linkInputRef.current) {
              linkInputRef.current.value = '';
            }

            if (onClear) {
              onClear();
            }
          },
        })
      );
    }
  };

  const onOpenInNewIconClick = () => {
    window.open(proprieteLink.url, '_blank');
  };

  const proprieteLinkIsSet = useMemo(() => !!proprieteLink, [proprieteLink]);

  useEffect(() => {
    if (!debouncedSearchTerm) {
      return;
    }

    if (!propriete.id) {
      setError(
        'La propriété doit être enregistrée pour pouvoir lier un dossier Sylvalibre.'
      );
      return;
    }

    setError('');

    dispatch(
      setProprieteLink({
        proprieteId: propriete.id,
        url: debouncedSearchTerm,
        setIsLoading,
        setError,
      })
    );
  }, [debouncedSearchTerm]);

  return (
    <>
      <TextField
        label='Lien vers dossier Sylvalibre'
        fullWidth
        autoComplete='off'
        inputRef={linkInputRef}
        onChange={(e) => {
          const value = e.target.value;

          if (!value) {
            return;
          }

          setSearchTerm(value.trim());
          e.target.focus();
        }}
        disabled={proprieteLinkIsSet}
        InputProps={{
          endAdornment: (
            <>
              {isLoading && <CircularProgress size={20} />}
              {(searchTerm || proprieteLinkIsSet) && (
                <IconButton onClick={onClearIconClick} color='primary'>
                  <ClearIcon />
                </IconButton>
              )}
            </>
          ),
        }}
      />
      {error && (
        <Box
          mt={1}
          p={2}
          border={1}
          borderColor='error.main'
          borderRadius={1}
          color='error.main'
          display='flex'
          alignItems='center'
        >
          <ErrorIcon />
          <Typography ml={1}>{error}</Typography>
        </Box>
      )}
      {proprieteLinkIsSet && (
        <Box
          mt={1}
          p={2}
          border={1}
          borderColor='primar.main'
          borderRadius={1}
          color='primary.main'
          display='flex'
          alignItems='center'
        >
          <Typography mr={1}>{proprieteLink.name}</Typography>
          <IconButton onClick={onOpenInNewIconClick}>
            <OpenInNewIcon />
          </IconButton>
        </Box>
      )}
    </>
  );
}

SylvalibreProprieteLink.propTypes = {
  propriete: PropTypes.object,
  onClear: PropTypes.func,
};
