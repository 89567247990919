import React, { forwardRef } from 'react';

import PropTypes from 'prop-types';

import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';

import UniteGestionForm from './UniteGestionForm';

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});

export default function UniteGestionFormDialog({
  editItem,
  getRefreshItem,
  stationsList,
  open,
  setOpen,
  onSubmit,
  SylvalibreLinkComponent,
  sylvalibreUgLinkIsAvailable,
}) {

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullScreen
      TransitionComponent={Transition}
    >
      <UniteGestionForm
        editItem={editItem}
        getRefreshItem={getRefreshItem}
        stationsList={stationsList}
        onSubmit={onSubmit}
        handleClose={handleClose}
        SylvalibreLinkComponent={SylvalibreLinkComponent}
        sylvalibreUgLinkIsAvailable={sylvalibreUgLinkIsAvailable}
      />
    </Dialog>
  );
}

UniteGestionFormDialog.propTypes = {
  editItem: PropTypes.object,
  getRefreshItem: PropTypes.func.isRequired,
  stationsList: PropTypes.array,
  setOpen: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  SylvalibreLinkComponent: PropTypes.elementType,
  sylvalibreUgLinkIsAvailable: PropTypes.bool,
};
