import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useWatch } from 'react-hook-form';
import BaseTextField from './BaseTextField';

/**
 * NumberTextField component
 *
 * A controlled text field component for handling numeric input with optional decimal precision.
 *
 * @param {Object} props - The component props
 * @param {number} props.decimal - The number of decimal places to format the value
 * @param {Object} props.control - The control object from react-hook-form
 * @param {string} props.name - The name of the field
 * @returns {JSX.Element} The rendered component
 */
export default function NumberTextField({ decimal, control, name, ...props }) {
  // Watch the value of the field from react-hook-form
  const valueWatch = useWatch({ control, name });
  // State to track if a comma is used as the decimal separator
  const [useComma, setUseComma] = useState(false);

  return (
    <BaseTextField
      control={control}
      name={name}
      onBlur={(e, onChange) => {
        let val = e.target.value;
        if (val === '') {
          // If the input is empty, set the value to null
          onChange(null);
          return;
        }
        // Check if the value contains a comma
        setUseComma(val.includes?.(','));
        // Replace comma with a dot and parse the value as a float
        val = parseFloat(val?.replace?.(',', '.'));
        // If the parsed value is a valid number, format it to the specified decimal places
        if (!isNaN(val)) {
          val = val.toFixed(decimal);
        }
        // Update the value in the form
        onChange(val);
      }}
      onFocus={() => setUseComma(false)}
      inputMode={'numeric'}
      valueFormatter={() => {

        if (isNaN(valueWatch)) {
          return;
        }

        // Format the value to use a comma if useComma is true
        if (useComma) {
          return valueWatch?.toString().replace('.', ',');
        }

        return valueWatch?.toString();
      }}
      {...props}
    />
  );
}

NumberTextField.propTypes = {
  decimal: PropTypes.number.isRequired,
  control: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
};