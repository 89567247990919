import React from 'react';

import PropTypes from 'prop-types';

import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { GridActionsCellItem } from '@mui/x-data-grid';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

import StatefulDataGrid from '@/components/StatefulDataGrid';
import TabPanel from '@/components/TabPanel';

import { clearCurrentItem, deleteItem } from '@/slices/itemSlice';

import useItems from '@/hooks/useItems';
import useChoices from '@/hooks/useChoices';
import useConfirmationDialog from '@/hooks/useConfirmationDialog';

export default function CouvertExistantTab({ tabValue }) {
  const dispatch = useDispatch();

  const { open } = useConfirmationDialog();

  const navigate = useNavigate();

  const itemType = 'sylvalibre_couvert_existant';

  const items = useItems(itemType);

  const handleEditItem = (itemId) => {
    dispatch(clearCurrentItem());
    navigate(`/sylvalibre-couvert-existant/${itemId}`);
  };

  const handleDeleteItem = async (itemId) => {
    const result = await open({
      title: 'Suppression',
      content: 'Confirmer la suppression de la correspondance ?',
    });

    if (result === 'confirm') {
      dispatch(deleteItem({ itemId, itemType }));
    }
  };

  const handleFormRedirect = () => {
    navigate('/sylvalibre-couvert-existant');
  };


  const choices = useChoices(itemType);

  const columns = [
    {
      field: 'actions',
      type: 'actions',
      width: 80,
      getActions: (params) => [
        <GridActionsCellItem
          key={params.id}
          icon={<EditIcon />}
          label='Éditer'
          onClick={() => handleEditItem(params.id)}
        />,
        <GridActionsCellItem
          key={params.id}
          icon={<DeleteIcon />}
          label='Supprimer'
          onClick={() => handleDeleteItem(params.id)}
        />,
      ],
    },
    {
      field: 'legende',
      headerName: 'Légende',
      width: 420,
      editable: false,
    },
    {
      field: 'peuplement_initial_type',
      headerName: 'Type de peuplement existant',
      type: 'singleSelect',
      valueOptions: choices?.peuplements_initiaux_types.map((item) => item.nom),
      width: 400,
      editable: false,
      valueGetter: (value) => value?.nom,
    },
  ];

  return (
    <TabPanel currentValue={tabValue} index={0}>
      <Grid container>
        <Button
          onClick={handleFormRedirect}
          sx={{ mt: 3, mb: 1 }}
          fullWidth={false}
          variant='contained'
        >
          Créer une nouvelle correspondance
        </Button>
      </Grid>
      <StatefulDataGrid boxSx={{ my: 2 }} columns={columns} rows={items} />
    </TabPanel>
  );
}

CouvertExistantTab.propTypes = {
  tabValue: PropTypes.number.isRequired,
};
