import { format } from 'date-fns';

export const toISODate = (dateStr) => {
  let date;
  try {
    date = dateStr ? format(new Date(dateStr), 'yyyy-MM-dd') : null;
  } catch {}

  return date;
};

export const fromISODate = (dateStr) =>
  dateStr ? format(new Date(dateStr), 'dd/MM/yyyy') : null;

export const fromISODateTime = (datetimeStr, useVerboseFormat = false) => {
  const formatStr = useVerboseFormat
    ? "'le' dd/MM/yyyy 'à' HH:mm"
    : 'dd/MM/yyyy HH:mm';

  return datetimeStr ? format(new Date(datetimeStr), formatStr) : null;
};
