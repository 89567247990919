import APIService from '@/APIService';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { setMessage } from './messageSlice';
import {
  fetchFilteredItems,
  setCurrentItemEntry,
  setFilteredItems,
} from './itemSlice';
import { setIsLoading } from './isLoadingSlice';

export const removeProprieteLink = createAsyncThunk(
  'propriete/remove',
  async (
    { proprieteId, setIsLoading: customSetIsLoading, setError, onSuccess },
    { rejectWithValue, dispatch }
  ) => {
    customSetIsLoading(true);

    const res = await APIService.delete({
      url: `sylvalibre-link/${proprieteId}`,
      onError: ({ data }) => {
        setError(data.message || data.detail);

        rejectWithValue(data);
        customSetIsLoading(false);
      },
    });

    onSuccess();

    dispatch(setMessage(res.data.message));
    dispatch(
      setCurrentItemEntry({
        key: 'sylvalibre_folder_uuid',
        value: null,
      })
    );

    customSetIsLoading(false);
  }
);

export const setProprieteLink = createAsyncThunk(
  'propriete/set',
  async (
    { proprieteId, url, setIsLoading: customSetIsLoading, setError },
    { rejectWithValue, dispatch }
  ) => {
    customSetIsLoading(true);

    const res = await APIService.post({
      url: `sylvalibre-link/${proprieteId}`,
      data: {
        url,
        propriete_id: proprieteId,
      },
      onError: ({ data }) => {
        customSetIsLoading(false);
        setError(data.message || data.detail);
        rejectWithValue(data);
      },
    });

    dispatch(
      setCurrentItemEntry({
        key: 'sylvalibre_folder_uuid',
        value: res.uuid,
      })
    );

    customSetIsLoading(false);

    return {
      proprieteLink: {
        uuid: res.data.uuid,
        name: res.data.name,
        url: res.data.url,
      },
    };
  }
);

export const fetchProprieteLink = createAsyncThunk(
  'propriete/fetch',
  async ({ proprieteId }, { rejectWithValue, dispatch }) => {
    dispatch(setIsLoading(true));

    const res = await APIService.get({
      url: `sylvalibre-link/${proprieteId}`,
      onError: ({ data }) => {
        const message = data.message || data.detail;
        if (message) {
          dispatch(setMessage(message));
        }
        rejectWithValue(data);

        dispatch(setIsLoading(false));
      },
    });

    dispatch(setIsLoading(false));

    return {
      proprieteLink: {
        name: res.data.name,
        url: res.data.url,
      },
    };
  }
);

export const syncContrat = createAsyncThunk(
  'contrat/sync',
  async ({ contratId }, { rejectWithValue, dispatch }) => {
    dispatch(setIsLoading(true));

    const res = await APIService.post({
      url: `sylvalibre-link/${contratId}/sync`,
      onError: ({ data }) => {
        if (data.message) {
          dispatch(setMessage(data.message));
        }

        if (data.detail) {
          dispatch(setSyncError(data.detail));
        }

        dispatch(setIsLoading(false));
        rejectWithValue(data);
      },
    });

    dispatch(setMessage(res.data.message));


    dispatch(
      fetchFilteredItems({
        itemType: 'unites_gestion',
        filter: { item_type: 'contrat_proprietaire', item_id: contratId },
      })
    );
    dispatch(
      fetchFilteredItems({
        itemType: 'parcelles_cadastrales_sous_contrat',
        filter: { contrat_proprietaire_id: contratId },
      })
    );

    dispatch(setSyncError(null));
    dispatch(
      setCurrentItemEntry({
        key: 'sylvalibre_latest_sync_datetime',
        value: res.data.latest_sync_datetime,
      })
    );

    dispatch(
      setCurrentItemEntry({
        key: 'surface_pressentie_sous_contrat',
        value:  res.data.surface_sous_contrat,
      })
    );

    dispatch(setIsLoading(false));

    return res.data.latest_sync_datetime;
  }
);

export const syncUg = createAsyncThunk(
  'ug/sync',
  async (
    { contratId, proprieteId, uniteGestionId },
    { rejectWithValue, dispatch }
  ) => {
    dispatch(setIsLoading(true));
    const res = await APIService.post({
      url: `sylvalibre-link/${proprieteId}/${uniteGestionId}/sync`,
      onError: ({ data }) => {
        if (data.message) {
          dispatch(setMessage(data.message));
        }

        if (data.detail) {
          dispatch(setSyncError(data.detail));
        }

        dispatch(setIsLoading(false));
        rejectWithValue(data);
      },
    });
    dispatch(setMessage(res.data.message));


    dispatch(
      fetchFilteredItems({
        itemType: 'unites_gestion',
        filter: { item_type: 'contrat_proprietaire', item_id: contratId },
      })
    );
    dispatch(
      fetchFilteredItems({
        itemType: 'parcelles_cadastrales_sous_contrat',
        filter: { contrat_proprietaire_id: contratId },
      })
    );

    dispatch(
      setCurrentItemEntry({
        key: 'surface_pressentie_sous_contrat',
        value:  res.data.surface_sous_contrat,
      })
    );

    dispatch(setSyncError(null));

    dispatch(setIsLoading(false));

    return res.data.latest_sync_datetime;
  }
);

export const fetchUgLink = createAsyncThunk(
  'ug/fetch',
  async ({ proprieteId, uniteGestionId }, { rejectWithValue, dispatch }) => {
    const res = await APIService.get({
      url: `sylvalibre-link/${proprieteId}/${uniteGestionId}`,
      onError: ({ data }) => {
        const message = data.message || data.detail;
        if (message) {
          dispatch(setMessage(message));
        }
        rejectWithValue(data);
      },
    });

    return {
      ugLink: {
        uuid: res.data.uuid,
        name: res.data.name,
        latest_sync_datetime: res.data.latest_sync_datetime,
      },
    };
  }
);

export const removeUgLink = createAsyncThunk(
  'ug/remove',
  async (
    { proprieteId, uniteGestionId },
    { rejectWithValue, dispatch, getState }
  ) => {
    dispatch(setIsLoading(true));

    const state = getState();
    const unitesGestionList = state.itemReducer.filteredItems.unites_gestion;

    const res = await APIService.delete({
      url: `sylvalibre-link/${proprieteId}/${uniteGestionId}`,
      onError: ({ data }) => {
        const message = data.message || data.detail;
        if (message) {
          dispatch(setMessage(message));
        }
        rejectWithValue(data);
        dispatch(setIsLoading(false));
      },
    });
    dispatch(setMessage(res.data.message));
    dispatch(
      setFilteredItems({
        itemsList: unitesGestionList.map((ug) => {
          if (ug.id === uniteGestionId) {
            return {
              ...ug,
              sylvalibre_ug_uuid: null,
              sylvalibre_latest_sync_datetime: null,
            };
          }
          return ug;
        }),
        itemType: 'unites_gestion',
      })
    );
    dispatch(setSyncError(null));

    dispatch(setIsLoading(false));
  }
);

export const setUgLink = createAsyncThunk(
  'ug/set',
  async (
    { proprieteId, uniteGestionId, ugUuid },
    { rejectWithValue, dispatch, getState }
  ) => {
    dispatch(setIsLoading(true));

    const state = getState();
    const unitesGestionList = state.itemReducer.filteredItems.unites_gestion;

    const res = await APIService.post({
      url: `sylvalibre-link/${proprieteId}/${uniteGestionId}`,
      data: {
        dessinlibre_ug_uuid: ugUuid,
      },
      onError: ({ data }) => {
        const message = data.message || data.detail;
        if (message) {
          dispatch(setMessage(message));
        }
        rejectWithValue(data);
        dispatch(setIsLoading(false));
      },
    });

    dispatch(clearUgList());
    dispatch(setMessage(res.data.message));
    dispatch(
      setFilteredItems({
        itemsList: unitesGestionList.map((ug) => {
          if (ug.id === uniteGestionId) {
            return {
              ...ug,
              sylvalibre_ug_uuid: res.data.uuid,
            };
          }
          return ug;
        }),
        itemType: 'unites_gestion',
      })
    );

    dispatch(setIsLoading(false));

    return {
      ugLink: {
        uuid: res.data.sylvalibre_ug_uuid,
        name: res.data.sylvalibre_ug_name,
      },
    };
  }
);

export const fetchUgList = createAsyncThunk(
  'ug-list',
  async (proprieteId, { rejectWithValue, dispatch }) => {
    dispatch(setIsLoading(true));

    const res = await APIService.get({
      url: `sylvalibre-link/${proprieteId}/ug-list`,
      onError: ({ data }) => {
        const message = data.message || data.detail;
        if (message) {
          dispatch(setMessage(message));
        }
        rejectWithValue(data);
        dispatch(setIsLoading(false));
      },
    });

    dispatch(setIsLoading(false));

    return { ugList: res.data.dessinlibre_ug_list };
  }
);

export const sylvalibreSlice = createSlice({
  name: 'sync',
  initialState: {
    ugList: null,
    ugLink: null,
    proprieteLink: null,
    syncError: null,
  },
  reducers: {
    clearProprieteLink: (state) => {
      state.proprieteLink = null;
    },
    clearUgList: (state) => {
      state.ugList = null;
    },
    clearUgLink: (state) => {
      state.ugLink = null;
    },
    setSyncError: (state, { payload }) => {
      state.syncError = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUgList.fulfilled, (state, { payload: { ugList } }) => {
      state.ugList = ugList;
    });
    builder.addCase(setUgLink.fulfilled, (state, { payload: { ugLink } }) => {
      state.ugLink = ugLink;
    });
    builder.addCase(removeUgLink.fulfilled, (state) => {
      state.ugLink = null;
    });
    builder.addCase(fetchUgLink.fulfilled, (state, { payload: { ugLink } }) => {
      state.ugLink = ugLink;
    });
    builder.addCase(syncUg.fulfilled, (state, { payload }) => {
      state.ugLink.latest_sync_datetime = payload;
    });
    builder.addCase(
      fetchProprieteLink.fulfilled,
      (state, { payload: { proprieteLink } }) => {
        state.proprieteLink = proprieteLink;
      }
    );
    builder.addCase(removeProprieteLink.fulfilled, (state) => {
      state.proprieteLink = null;
    });
    builder.addCase(
      setProprieteLink.fulfilled,
      (state, { payload: { proprieteLink } }) => {
        state.proprieteLink = proprieteLink;
      }
    );
  },
});

export const { clearUgList, clearUgLink, clearProprieteLink, setSyncError } =
  sylvalibreSlice.actions;

export default sylvalibreSlice.reducer;
