import React from 'react';

import PropTypes from 'prop-types';

import Grid from '@mui/material/Grid';

import useItems from '@/hooks/useItems';
import useDatagridSx from '@/hooks/useDatagridSx';

import SelectField from '@/components/controlled-fields/SelectField';
import AreaTextField from '@/components/controlled-fields/text-fields/AreaTextField';
import BaseTextField from '@/components/controlled-fields/text-fields/BaseTextField';
import DividerTitle from '@/components/DividerTitle';
import TabPanelForm from '@/components/TabPanelForm';
import CodePostalTextField from '@/components/controlled-fields/text-fields/CodePostalTextField';
import ParcelleCadastraleSousContratTable from '@/features/shared/parcelle-sous-contrat/ParcelleCadastraleSousContratTable';

import AdresseField from '@/features/shared/AdresseField';
import DepartementField from '@/features/shared/DepartementField';
import ParcelleCadastralePressentieSousContratTable from '@/features/shared/parcelle-cadastrale-pressentie-sous-contrat/ParcelleCadastralePressentieSousContratTable';
import ProprietaireTable from '@/features/shared/proprietaire/ProprietaireTable';

import SylvalibreProprieteLink from '@/features/shared/sylvalibre-link/SylvalibreProprieteLink';

export default function InformationsGeneralesTab({
  tabValue,
  form,
  proprietairesList,
  parcellesPressentiesList,
  parcellesCadastralesSousContratList,
  choices,
  propriete,
  isSylvalibreSynced,
}) {
  const { control, setValue } = form;


  const datagridSx = useDatagridSx();

  const usersList = useItems('users');
  return (
    <TabPanelForm currentValue={tabValue} index={0}>
      <form>
        <Grid container spacing={2} alignItems='center'>
          <Grid container item alignItems='center' spacing={2}>
            <Grid item xs={12} md={8} lg={5}>
              <SylvalibreProprieteLink propriete={propriete} />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <DividerTitle text='Informations propriété' />
          </Grid>
          <Grid container item spacing={2}>
            <Grid item xs={12} sm={6} md={3}>
              <BaseTextField
                control={control}
                label='Nom de la propriété'
                name='propriete.nom_commun'
                required='Nom de la propriété requis.'
              />
            </Grid>
            <Grid item xs={12} sm={6} md={5}>
              <AdresseField
                control={control}
                fieldName='propriete.adresse'
                relatedFiedlNames={{
                  commune: 'propriete.commune',
                  codePostal: 'propriete.code_postal',
                }}
                label='Adresse propriété'
                setValue={setValue}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <BaseTextField
                control={control}
                label='Commune propriété'
                name='propriete.commune'
                required='Commune de la propriété requise.'
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <CodePostalTextField
                control={control}
                name='propriete.code_postal'
                label='Code postal propriété'
                required='Code postal propriété requis.'
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <DepartementField
                control={control}
                departements={choices?.departements}
                label='Département propriété'
                departementFieldName='propriete.departement'
                codePostalFieldName='propriete.code_postal'
                setValue={setValue}
              />
            </Grid>
          </Grid>
          <Grid item container spacing={2} mt={1}>
            <Grid item xs={12} sm={6} md={4}>
              <AreaTextField
                control={control}
                label='Surface propriété (ha)'
                name='propriete.surface_totale_propriete'
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <SelectField
                control={control}
                name='propriete.cmf_neosylva'
                baseId='cmf-neosylva'
                label='CMF Néosylva'
                renderValue={(value) =>
                  `${value.first_name} ${value.last_name}`
                }
                values={usersList?.filter(
                  (user) => user.role.name === 'neosylva_cmf'
                )}
                itemMapper={(user) => ({
                  key: user.username,
                  label: `${user.first_name} ${user.last_name}`,
                })}
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <DividerTitle text='Informations propriétaires' />
          </Grid>
          <Grid item xs={12}>
            <ProprietaireTable items={proprietairesList} />
          </Grid>
          <Grid item xs={12}>
            <DividerTitle text='Parcellaire cadastral' />
          </Grid>
          <Grid item xs={12}>
            {isSylvalibreSynced || parcellesCadastralesSousContratList?.length > 0 ? (
              <ParcelleCadastraleSousContratTable
                parcellesCadastralesSousContratList={
                  parcellesCadastralesSousContratList
                }
                datagridSx={datagridSx}
                editable={false}
              />
            ) : (
              <ParcelleCadastralePressentieSousContratTable
                items={parcellesPressentiesList}
              />
            )}
          </Grid>
        </Grid>
      </form>
    </TabPanelForm>
  );
}

InformationsGeneralesTab.propTypes = {
  tabValue: PropTypes.number,
  form: PropTypes.object.isRequired,
  proprietairesList: PropTypes.array,
  parcellesPressentiesList: PropTypes.array,
  parcellesCadastralesSousContratList: PropTypes.array,
  choices: PropTypes.object,
  propriete: PropTypes.object,
  isSylvalibreSynced: PropTypes.bool,
};
