import React from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';

import useTabState from '@/hooks/useTabState';

import CouvertExistantTab from '@/features/sylvalibre-mapping/couvert-existant/CouvertExistantTab';
import OrientationsGestionTab from '@/features/sylvalibre-mapping/orientations-gestion/OrientationsGestionTab';
import TravauxPrevusTab from '@/features/sylvalibre-mapping/travaux-prevus/TravauxPrevusTab';

export default function SylvalibreMappings() {
  const [tabValue, handleTabChange] = useTabState(0);

  return (
    <Box component='main' mx={3} mt={2}>
      <Typography mb={2} variant='h5' component='div'>
        Correspondances Sylvalibre
      </Typography>
      <Tabs
        value={tabValue}
        onChange={handleTabChange}
        variant='scrollable'
        allowScrollButtonsMobile
      >
        <Tab label='Couvert existant' />
        <Tab label='Orientations de gestion' />
        <Tab label='Travaux prévus au contrat' />
      </Tabs>
      <CouvertExistantTab tabValue={tabValue} />
      <OrientationsGestionTab tabValue={tabValue} />
      <TravauxPrevusTab tabValue={tabValue} />
    </Box>
  );
}
