import React from 'react';

import { useParams } from 'react-router-dom';

import useItem from '@/hooks/useItem';

import TravauxPrevusForm from '@/features/sylvalibre-mapping/travaux-prevus/TravauxPrevusForm';

import Container from '@mui/material/Container';

export default function SylvalibreTravauxPrevus() {
  const params = useParams();

  const itemId = params.id ? parseInt(params.id, 10) : undefined;

  const emptyItem = {
    legende: null,
    itineraire_sylvicole_preconise: null,
  };

  const item = useItem(itemId, 'sylvalibre_travaux_prevus', emptyItem);

  return (
    <Container component='main' maxWidth={false} sx={{ my: 2 }}>
      {item && <TravauxPrevusForm item={item} />}
    </Container>
  );
}
