import React, { useEffect, useRef } from 'react';

import PropTypes from 'prop-types';
import { ErrorBoundary } from 'react-error-boundary';
import { useDispatch, useSelector } from 'react-redux';
import {
  Navigate,
  Outlet,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from 'react-router-dom';

import { logoutUser } from '@/slices/authSlice';

import APIService from '@/APIService';
import ConfirmationDialog from './components/ConfirmationDialog';
import ToastMessage from './components/ToastMessage';
import Navbar from './features/Navbar';
import useIsNeosylva from './hooks/useIsNeosylva';
import Account from './pages/Account';
import Certification from './pages/Certification';
import Certifications from './pages/Certifications';
import Contact from './pages/Contact';
import Contacts from './pages/Contacts';
import ContratEtf from './pages/ContratEtf';
import ContratFinanceur from './pages/ContratFinanceur';
import ContratProprietaire from './pages/ContratProprietaire';
import ContratsEtf from './pages/ContratsEtf';
import ContratsFinanceur from './pages/ContratsFinanceur';
import ContratsProprietaires from './pages/ContratsProprietaires';
import DepartementCmf from './pages/DepartementCmf';
import DepartementsCmf from './pages/DepartementsCmf';
import Depense from './pages/Depense';
import Depenses from './pages/Depenses';
import DiagnosticGestionnaire from './pages/DiagnosticGestionnaire';
import DiagnosticNeosylvaCreate from './pages/DiagnosticNeosylvaCreate';
import DiagnosticNeosylvaEdit from './pages/DiagnosticNeosylvaEdit';
import DocumentGestionDurable from './pages/DocumentGestionDurable';
import DocumentsGestionDurable from './pages/DocumentsGestionDurable';
import ElementBiodiversite from './pages/ElementBiodiversite';
import ElementsBiodiversite from './pages/ElementsBiodiversite';
import Entreprise from './pages/Entreprise';
import Entreprises from './pages/Entreprises';
import Error from './pages/Error';
import EspecePlanChasse from './pages/EspecePlanChasse.js';
import EspecesPlanChasse from './pages/EspecesPlanChasse';
import Essence from './pages/Essence';
import EssenceParcelle from './pages/EssenceParcelle';
import Essences from './pages/Essences';
import EssencesParcelles from './pages/EssencesParcelles';
import FicheContrat from './pages/FicheContrat';
import FichePropriete from './pages/FichePropriete';
import FinanceurCarbone from './pages/FinanceurCarbone';
import FinanceursCarbone from './pages/FinanceursCarbone';
import GestionnairePro from './pages/GestionnairePro';
import GestionnairesPro from './pages/GestionnairesPro';
import Home from './pages/Home';
import Intervention from './pages/Intervention';
import InterventionPassee from './pages/InterventionPassee';
import InterventionsPassees from './pages/InterventionPassees';
import Interventions from './pages/Interventions';
import Login from './pages/Login';
import NouveauContact from './pages/NouveauContact';
import ParcelleCadastralePressentieSousContrat from './pages/ParcelleCadastralePressentieSousContrat';
import ParcellesCadastralesPressentiesSousContrat from './pages/ParcellesCadastralesPressentiesSousContrat';
import PasswordReset from './pages/PasswordReset';
import PasswordResetDemand from './pages/PasswordResetDemand';
import PeuplementInitial from './pages/PeuplementInitial';
import PeuplementInitiaux from './pages/PeuplementInitiaux';
import ProgrammeRd from './pages/ProgrammeRd';
import ProgrammesRd from './pages/ProgrammesRd';
import Proprietaire from './pages/Proprietaire';
import Proprietaires from './pages/Proprietaires';
import Propriete from './pages/Propriete';
import Proprietes from './pages/Proprietes';
import Recette from './pages/Recette';
import Recettes from './pages/Recettes';
import RendezVousAnnuel from './pages/RendezVousAnnuel';
import RendezVousAnnuels from './pages/RendezVousAnnuels';
import ResponsableEtf from './pages/ResponsableEtf';
import ResponsablesEtf from './pages/ResponsablesEtf';
import ServiceEnv from './pages/ServiceEnv';
import ServicesEnv from './pages/ServicesEnv';
import Station from './pages/Station';
import Stations from './pages/Stations';
import StockCarbone from './pages/StockCarbone';
import StocksCarbone from './pages/StocksCarbone';
import TableauBordCMF from './pages/TableauBordCmf';
import UniteGestion from './pages/UniteGestion';
import UnitesGestion from './pages/UnitesGestion';
import User from './pages/User';
import Users from './pages/Users';
import ConstatRepriseEssence from './pages/ConstatRepriseEssence';
import ConstatsReprisesEssences from './pages/ConstatsReprisesEssences';
import SylvalibreMappings from './pages/SylvalibreMappings';
import SylvalibreCouvertExistant from './pages/SylvalibreCouvertExistant';
import SylvalibreOrientationsGestion from './pages/SylvalibreOrientationsGestion';
import SylvalibreTravauxPrevus from './pages/SylvalibreTravauxPrevus';

export default function App() {
  const isLoggedIn = useSelector(
    (store) => store.persistAuthReducer.isLoggedIn
  );
  const tokenExpiration = useSelector(
    (store) => store.persistAuthReducer.user?.token_expiration
  );
  const isAdmin = useSelector(
    (store) => store.persistAuthReducer.user?.is_admin
  );
  const username = useSelector(
    (store) => store.persistAuthReducer.user?.username
  );
  const isNeosylva = useIsNeosylva();
  const location = useLocation();

  const dispatch = useDispatch();

  const loggoutTimerRef = useRef(null);

  useEffect(() => {
    const resetTimeout = () => {
      clearTimeout(loggoutTimerRef.current);
      loggoutTimerRef.current = null;
    };

    if (isLoggedIn) {
      if (!loggoutTimerRef.current) {
        // On démarre un timer qui déconnecte l'utilisateur à l'expiration du jeton
        loggoutTimerRef.current = setTimeout(() => {
          dispatch(
            logoutUser('Session expirée, veuillez vous reconnecter.')
          ).then(({ payload }) => {
            if (payload) {
              navigate('/login');
            }
          });
        }, tokenExpiration - Date.now());
      }
    } else {
      // On annule le timer car l'utilisateur n'est plus connecté
      resetTimeout();
    }
    // On annule le timer car la page a été fermée/rechargée
    return resetTimeout;
  }, [isLoggedIn]);

  const onUIError = async (error, info) => {
    if (process.env.NODE_ENV === 'development') {
      return;
    }
    const err = {
      message: error.message,
      stack: error.stack,
    };
    await APIService.post({
      url: 'on-error',
      data: {
        error: err,
        info: info.componentStack,
        username: username,
        url: location.pathname,
      },
      withCredentials: false,
    });
  };

  const navigate = useNavigate();
  const resetState = () => {
    navigate('/');
  };

  const AuthProtectedRoute = ({ loggedIn, redirectPath = '/login' }) => {
    if (!loggedIn) {
      return <Navigate to={redirectPath} />;
    }
    return <Outlet />;
  };

  AuthProtectedRoute.propTypes = {
    loggedIn: PropTypes.bool.isRequired,
    redirectPath: PropTypes.string,
  };

  const AdminProtectedRoute = ({ userIsAdmin, redirectPath = '/' }) => {
    if (!userIsAdmin) {
      return <Navigate to={redirectPath} />;
    }

    return <Outlet />;
  };

  AdminProtectedRoute.propTypes = {
    userIsAdmin: PropTypes.bool,
    redirectPath: PropTypes.string,
  };

  const NeosylvaProtectedRoute = ({ userIsNeosylva, redirectPath = '/' }) => {
    if (!userIsNeosylva) {
      return <Navigate to={redirectPath} />;
    }

    return <Outlet />;
  };

  NeosylvaProtectedRoute.propTypes = {
    userIsNeosylva: PropTypes.bool.isRequired,
    redirectPath: PropTypes.string,
  };

  const CmfProtectedRoute = ({ userIsCmf, redirectPath = '/' }) => {
    if (!userIsCmf) {
      return <Navigate to={redirectPath} />;
    }

    return <Outlet />;
  };

  CmfProtectedRoute.propTypes = {
    userIsCmf: PropTypes.bool.isRequired,
    redirectPath: PropTypes.string,
  };

  return (
    <ErrorBoundary
      FallbackComponent={Error}
      onError={onUIError}
      onReset={resetState}
    >
      <Navbar />
      <ToastMessage />
      <ConfirmationDialog />
      <Routes>
        <Route element={<AuthProtectedRoute loggedIn={isLoggedIn} />}>
          <Route path='/' element={<Home />} />
          <Route path='/account/:id' element={<Account />} />
          <Route element={<AdminProtectedRoute userIsAdmin={isAdmin} />}>
            <Route path='/users' element={<Users />} />
            <Route path='/user'>
              <Route path=':id' element={<User />} />
              <Route path='' element={<User />} />
            </Route>

            <Route path='/departements-cmf' element={<DepartementsCmf />} />
            <Route path='/departement-cmf/:id' element={<DepartementCmf />} />
            <Route
              path='/sylvalibre-mappings'
              element={<SylvalibreMappings />}
            />
            <Route path='/sylvalibre-couvert-existant'>
              <Route path='' element={<SylvalibreCouvertExistant />} />
              <Route path=':id' element={<SylvalibreCouvertExistant />} />
            </Route>
            <Route path='/sylvalibre-travaux-prevus'>
              <Route path='' element={<SylvalibreTravauxPrevus />} />
              <Route path=':id' element={<SylvalibreTravauxPrevus />} />
            </Route>
            <Route path='/sylvalibre-orientations-gestion'>
              <Route path='' element={<SylvalibreOrientationsGestion />} />
              <Route path=':id' element={<SylvalibreOrientationsGestion />} />
            </Route>
            <Route path='/essences' element={<Essences />} />
            <Route path='/essence'>
              <Route path=':id' element={<Essence />} />
              <Route path='' element={<Essence />} />
            </Route>
            <Route
              path='/interventions-passees'
              element={<InterventionsPassees />}
            />
            <Route path='/intervention-passee'>
              <Route path=':id' element={<InterventionPassee />} />
              <Route path='' element={<InterventionPassee />} />
            </Route>
            <Route
              path='/documents-gestion-durable'
              element={<DocumentsGestionDurable />}
            />
            <Route path='/document-gestion-durable'>
              <Route path=':id' element={<DocumentGestionDurable />} />
              <Route path='' element={<DocumentGestionDurable />} />
            </Route>
            <Route path='/certifications' element={<Certifications />} />
            <Route path='/certification'>
              <Route path=':id' element={<Certification />} />
              <Route path='' element={<Certification />} />
            </Route>
            <Route
              path='/elements-biodiversite'
              element={<ElementsBiodiversite />}
            />
            <Route path='/element-biodiversite'>
              <Route path=':id' element={<ElementBiodiversite />} />
              <Route path='' element={<ElementBiodiversite />} />
            </Route>
            <Route path='/stations' element={<Stations />} />
            <Route path='/station'>
              <Route path=':id' element={<Station />} />
              <Route path='' element={<Station />} />
            </Route>
            <Route
              path='/especes-plan-chasse'
              element={<EspecesPlanChasse />}
            />
            <Route path='/espece-plan-chasse'>
              <Route path=':id' element={<EspecePlanChasse />} />
              <Route path='' element={<EspecePlanChasse />} />
            </Route>
            <Route
              path='/parcelles-cadastrales'
              element={<ParcellesCadastralesPressentiesSousContrat />}
            />
            <Route path='/parcelle-cadastrale-pressentie-sous-contrat'>
              <Route
                path=':id'
                element={<ParcelleCadastralePressentieSousContrat />}
              />
              <Route
                path=''
                element={<ParcelleCadastralePressentieSousContrat />}
              />
            </Route>
            <Route path='/unites-gestion' element={<UnitesGestion />} />
            <Route path='/unite-gestion'>
              <Route path=':id' element={<UniteGestion />} />
              <Route path='' element={<UniteGestion />} />
            </Route>
            <Route path='/essences-parcelles' element={<EssencesParcelles />} />
            <Route path='/essence-parcelle'>
              <Route path=':id' element={<EssenceParcelle />} />
              <Route path='' element={<EssenceParcelle />} />
            </Route>
            <Route
              path='/constats-reprises-essences'
              element={<ConstatsReprisesEssences />}
            />
            <Route path='/constat-reprise-essence'>
              <Route path=':id' element={<ConstatRepriseEssence />} />
              <Route path='' element={<ConstatRepriseEssence />} />
            </Route>
            <Route path='/unite-gestion' element={<UniteGestion />} />
            <Route path='/contrats-financeur' element={<ContratsFinanceur />} />
            <Route path='/contrat-financeur'>
              <Route path=':id' element={<ContratFinanceur />} />
              <Route path='' element={<ContratFinanceur />} />
            </Route>
            <Route path='/interventions' element={<Interventions />} />
            <Route path='/intervention'>
              <Route path=':id' element={<Intervention />} />
              <Route path='' element={<Intervention />} />
            </Route>
            <Route
              path='/peuplements-initiaux'
              element={<PeuplementInitiaux />}
            />
            <Route path='/peuplement-initial'>
              <Route path=':id' element={<PeuplementInitial />} />
              <Route path='' element={<PeuplementInitial />} />
            </Route>
            <Route path='/stocks-carbone' element={<StocksCarbone />} />
            <Route path='/stock-carbone'>
              <Route path=':id' element={<StockCarbone />} />
              <Route path='' element={<StockCarbone />} />
            </Route>
            <Route path='/depenses' element={<Depenses />} />
            <Route path='/depense'>
              <Route path=':id' element={<Depense />} />
              <Route path='' element={<Depense />} />
            </Route>
            <Route path='/recettes' element={<Recettes />} />
            <Route path='/recette'>
              <Route path=':id' element={<Recette />} />
              <Route path='' element={<Recette />} />
            </Route>
            <Route path='/services-env' element={<ServicesEnv />} />
            <Route path='/service-env'>
              <Route path=':id' element={<ServiceEnv />} />
              <Route path='' element={<ServiceEnv />} />
            </Route>
            <Route
              path='/rendez-vous-annuels'
              element={<RendezVousAnnuels />}
            />
            <Route path='/rendez-vous-annuel'>
              <Route path=':id' element={<RendezVousAnnuel />} />
              <Route path='' element={<RendezVousAnnuel />} />
            </Route>
            <Route path='/financeurs-carbone' element={<FinanceursCarbone />} />
            <Route path='/financeur-carbone'>
              <Route path=':id' element={<FinanceurCarbone />} />
              <Route path='' element={<FinanceurCarbone />} />
            </Route>
            <Route path='/responsables-etf' element={<ResponsablesEtf />} />
            <Route path='/responsable-etf'>
              <Route path=':id' element={<ResponsableEtf />} />
              <Route path='' element={<ResponsableEtf />} />
            </Route>
            <Route path='/contrats-etf' element={<ContratsEtf />} />
            <Route path='/contrat-etf'>
              <Route path=':id' element={<ContratEtf />} />
              <Route path='' element={<ContratEtf />} />
            </Route>
            <Route path='/programmes-rd' element={<ProgrammesRd />} />
            <Route path='/programme-rd'>
              <Route path=':id' element={<ProgrammeRd />} />
              <Route path='' element={<ProgrammeRd />} />
            </Route>
            <Route
              path='/contrats-proprietaires'
              element={<ContratsProprietaires />}
            />
            <Route path='/contrat-proprietaire'>
              <Route path=':id' element={<ContratProprietaire />} />
              <Route path='' element={<ContratProprietaire />} />
            </Route>
            <Route path='/proprietes' element={<Proprietes />} />
            <Route path='/propriete'>
              <Route path=':id' element={<Propriete />} />
              <Route path='' element={<Propriete />} />
            </Route>
          </Route>
          <Route
            path='/diagnostic-gestionnaire'
            element={<DiagnosticGestionnaire />}
          />
          <Route
            element={<NeosylvaProtectedRoute userIsNeosylva={isNeosylva} />}
          >
            <Route path='/contacts' element={<Contacts />} />
            <Route path='/contact'>
              <Route path=':id' element={<Contact />} />
              <Route path='' element={<Contact />} />
            </Route>

            <Route path='/proprietaires' element={<Proprietaires />} />
            <Route path='/proprietaire/:id' element={<Proprietaire />} />

            <Route path='/entreprises' element={<Entreprises />} />
            <Route path='/entreprise'>
              <Route path=':id' element={<Entreprise />} />
              <Route path='' element={<Entreprise />} />
            </Route>
            <Route path='/gestionnaires-pro' element={<GestionnairesPro />} />
            <Route path='/gestionnaire-pro/:id' element={<GestionnairePro />} />
            <Route path='/nouveau-contact' element={<NouveauContact />} />
            <Route
              path='/creer-diagnostic-neosylva'
              element={<DiagnosticNeosylvaCreate />}
            />
            <Route
              path='/modifier-diagnostic-neosylva'
              element={<DiagnosticNeosylvaEdit />}
            />
            <Route path='/tableau-bord-cmf' element={<TableauBordCMF />} />
            <Route path='/fiche-propriete'>
              <Route path=':id' element={<FichePropriete />} />
              <Route path='' element={<FichePropriete />} />
            </Route>
            <Route path='/fiche-contrat'>
              <Route path=':id' element={<FicheContrat />} />
              <Route path='' element={<FicheContrat />} />
            </Route>
          </Route>
        </Route>
        <Route path='/login' element={<Login />} />
        <Route
          path='/password-reset-demand'
          element={<PasswordResetDemand />}
        />
        <Route path='/password-reset/:token' element={<PasswordReset />} />
      </Routes>
    </ErrorBoundary>
  );
}
